import React, { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { staff } from "../../../api/staff";
import CustomDropdown from "../../../components/CustomDropDown/CustomDropDown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAnglesRight } from "@fortawesome/free-solid-svg-icons";
import toast from "react-hot-toast";

const ViewStaffPermission = ({
  permissions,
  userPermissions,
  refetch,
  handleModal,
}) => {
  const [isFresh, setIsFresh] = useState(true);
  const [defaultPermissions, setDefaultPermissions] = useState([]);
  const currentStaff = JSON.parse(sessionStorage.getItem("currentStaff"));
  const { mutateAsync } = useMutation(staff.updateStaff);

  useEffect(() => {
    isFresh &&
      setDefaultPermissions(
        userPermissions?.map((permission) => {
          return { value: permission, label: permission };
        })
      );
  }, [isFresh, userPermissions]);

  // console.log(permissions);

  // console.log(userPermissions);

  const handleSelected = (selected) => {
    setIsFresh(false);

    if (selected.length < defaultPermissions.length) {
      return setDefaultPermissions(selected);
    }

    // Create a new array by combining elements from defaultPermissions and selected
    const updatedPermissions = [...defaultPermissions];

    selected.forEach((item) => {
      // Check if item is not already in updatedPermissions
      if (!updatedPermissions.some((perm) => perm.value === item.value)) {
        updatedPermissions.push(item);
      }
    });

    setDefaultPermissions(updatedPermissions);
  };

  const updatePermissions = async () => {
    toast.loading("Updating...");
    const payload = {
      lName: currentStaff?.lName,
      permissions: defaultPermissions?.map((permission) => permission?.value),
    };

    // console.log(payload);

    try {
      const data = await mutateAsync({
        id: currentStaff?._id,
        payload,
      });
      toast.remove();
      toast.success(data?.message);
      refetch();
      setTimeout(() => {
        handleModal();
        location.reload();
      }, 1000);
    } catch ({ response }) {
      console.log({ response });
      toast.remove();
      toast.error(response?.data?.message);
    }
  };

  return (
    <div className="h-[500px]">
      <CustomDropdown
        isMulti={true}
        width={"100%"}
        onChange={(selected) => handleSelected(selected)}
        value={defaultPermissions ? defaultPermissions : []}
        options={permissions
          ?.filter((permission) => {
            // Filter out permissions that are already in defaultPermissions
            return !defaultPermissions.some(
              (item) => item.value === permission?.permission
            );
          })
          .map((permission) => {
            return {
              value: permission?.permission,
              label: permission?.permission,
            };
          })}
      />
      <div className="my-3 w-full flex items-center justify-end">
        <button
          className="text-sm bg-darkBlue w-[150px] text-white rounded px-5 py-2 flex items-center justify-center gap-1"
          onClick={updatePermissions}
        >
          Proceed
          <FontAwesomeIcon icon={faAnglesRight} style={{ fontSize: "11px" }} />
        </button>
      </div>
    </div>
  );
};

export default ViewStaffPermission;
