import React from "react";
import { useMutation } from "react-query";
import toast from "react-hot-toast";
import { useForm } from "react-hook-form";
import { caroptions } from "../../../api/caroptions";

const AddType = () => {
  const { register, handleSubmit } = useForm();

  const { mutateAsync } = useMutation(caroptions.addCarType);

  const onSubmit = async (values) => {
    toast.loading("Processing...");

    try {
      const data = await mutateAsync(values);
      //   console.log(data);
      toast.remove();
      toast.success(data?.message);
      setTimeout(() => {
        location.reload();
      }, 1000);
    } catch ({ response }) {
      // console.log({ response });
      toast.remove();
      toast.error(response?.data?.message);
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="w-full flex items-center justify-center gap-3">
          <div className="flex-1 flex items-start flex-col justify-center gap-1">
            {/* <p>Enter title</p> */}
            <input
              className="w-full flex-1 border border-slate-300 block px-4 py-2 rounded-md outline-none bg-slate-100 duration-200 focus:ring-0 focus:px-5 focus:border-darkBlue focus:bg-white"
              required
              type={"text"}
              {...register("carType", {
                required: true,
              })}
            />
          </div>
        </div>
        <div className="w-full flex items-center justify-start">
          <input
            type="submit"
            value="SUBMIT"
            className="mt-5 w-full px-5 py-2 border border-darkBlue cursor-pointer bg-darkBlue text-white rounded duration-100"
          />
        </div>
      </form>
    </div>
  );
};

export default AddType;
