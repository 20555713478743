import React from "react";
import { useMutation } from "react-query";
import { vendors } from "../../../api/vendors";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";

const ActivateDeactivateDriver = ({ handleModal, currentDriver }) => {
  const { mutateAsync } = useMutation(vendors.activateDeactiveDriverAccount);

  const { register, handleSubmit } = useForm();

  const handleActivate = async (values) => {
    const payload = {
      activate: currentDriver?.accountStatus === "no" ? "yes" : "no",
      ...values,
    };
    try {
      toast.loading("Processing...");
      const data = await mutateAsync({ id: currentDriver?._id, payload });
      //   console.log(data);
      toast.remove();
      toast.success(data?.message);
      setTimeout(() => {
        handleModal();
        location.reload();
      }, 1000);
    } catch ({ response }) {
      toast.remove();
      toast.error(response?.data?.message);
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit(handleActivate)}>
        <div className="w-full">
          <div className="flex-1 flex items-start flex-col justify-center gap-1">
            <p className="uppercase font-medium">Enter your Reason</p>
            <textarea
              className="w-full flex-1 border border-slate-300 block px-4 py-2 rounded-md outline-none bg-slate-100 duration-200 focus:ring-0 focus:px-5 focus:border-orangeBg focus:bg-white"
              rows={5}
              {...register("reason", {
                required: true,
              })}
            />
          </div>
        </div>
        <div className="w-full flex items-center justify-start">
          <input
            type="submit"
            value="SUBMIT"
            className="mt-5 px-5 py-2 border border-orangeBg cursor-pointer bg-orangeBg text-white rounded duration-100 hover:text-darkBlue hover:bg-orange-200 hover:border-orange-200"
          />
        </div>
      </form>
    </div>
  );
};

export default ActivateDeactivateDriver;
