import react, { useState } from "react";
import {
  CheckOutlined,
  ClearOutlined,
  CloseOutlined,
} from "@material-ui/icons";
import Host from "./components/Host";
import Car from "./components/Car";
import Driver from "./components/Driver";
import CarStory from "./components/CarStory";
import Loader from "../../components/Loader/Loader";
const ApprovalType = ({ type, data, isLoading }) => {
  // console.log(data);

  if (isLoading) {
    return <Loader />;
  }

  if (type === "car") {
    return <Car data={data} />;
  } else {
    return <Driver data={data} />;
  }
};

export default ApprovalType;
