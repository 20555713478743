import apiClient from "./api";

const client = apiClient();

export const longtermleasing = {
  getOpenLeasing: () =>
    client
      .get(`/api/v1/getOpenLongTermListing?limit=50&page=1`)
      .then(({ data }) => data),

  acceptOpenListing: (id) =>
    client.post(`/api/v1/acceptLongTermListing/${id}`).then(({ data }) => data),

  getAssignedLongTermLeasing: ({ search, currentPage, perPage }) =>
    client
      .get(
        `/api/v1/getAssignedLongTermListing?limit=${perPage}&page=${currentPage}&search=${search}`
      )
      .then(({ data }) => data),

  getLongTermListing: (id) =>
    client.get(`/api/v1/getLongTermListing/${id}`).then(({ data }) => data),

  closeLongTermListing: ({ id, payload }) =>
    client
      .post(`/api/v1/closeLongTermListing/${id}`, payload)
      .then(({ data }) => data),
};
