import React, { useState } from "react";
import { useQuery, useMutation } from "react-query";
import { caroptions } from "../../../api/caroptions";
import Pagination from "../../../components/Pagination/Pagination";
import Loader from "../../../components/Loader/Loader";
import Search from "../../../components/Search/Search";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import toast from "react-hot-toast";
import CenteredModal from "../../../components/Modal/CenteredModal";
import AddModel from "../modals/AddModel";
import { Close } from "@material-ui/icons";

const Make = () => {
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(5);

  const [modal, showModal] = useState(false);

  const handleModal = () => {
    showModal(!modal);
  };

  const { mutateAsync } = useMutation(caroptions.deleteCarModel);

  const { data, isLoading, refetch } = useQuery(
    ["Car Models", { search, currentPage, perPage }],
    ({ queryKey }) => caroptions.getCarModel(queryKey[1]),
    {
      cacheTime: 30000,
      staleTime: 30000,
      refetchOnWindowFocus: true,
      select: (data) => data,
    }
  );

  const { data: carMakes } = useQuery(
    ["Car Makes", { currentPage, perPage }],
    ({ queryKey }) =>
      caroptions.getCarMake({
        currentPage: 1,
        perPage: 1000000000,
        search: "",
      }),
    {
      cacheTime: 30000,
      staleTime: 30000,
      refetchOnWindowFocus: true,
      select: (data) => data?.data,
    }
  );

  // console.log(carMakes);

  const handleDelete = async (id) => {
    if (confirm("Are you sure you want to delete this car model?")) {
      toast.loading("Processing...");
      try {
        const data = await mutateAsync(id);
        toast.remove();
        toast.success(data?.message);
        setTimeout(() => {
          location.reload();
        }, 1000);
      } catch ({ response }) {
        toast.remove();
        toast.error(response?.data?.message);
      }
    }
  };

  // console.log(data);

  return (
    <React.Fragment>
      <CenteredModal
        title={"Add Make"}
        open={modal}
        setOpen={handleModal}
        closeButtonLabel={<Close />}
      >
        <AddModel data={carMakes} />
      </CenteredModal>
      <div className="w-full my-5 flex items-center justify-between gap-3">
        <Search setCurrentPage={setCurrentPage} setSearch={setSearch} />
        <button
          className="bg-darkBlue uppercase w-[140px] text-white px-5 py-[6.7px]"
          onClick={handleModal}
        >
          Add Model
        </button>
      </div>
      <ul>
        {data?.data?.map((item, index) => (
          <li
            style={{ boxShadow: "3px 3px 1px 0 #023047" }}
            key={index}
            className="bg-white flex items-center justify-between rounded-md border border-slate-300 py-4 w-[99.7%] px-5 my-6"
          >
            <p>
              {index + 1 + ((data?.data_count?.currentPage || 1) - 1) * perPage}
              . {item?.model}
            </p>
            <button onClick={() => handleDelete(item?._id)}>
              <FontAwesomeIcon icon={faTrash} className="text-orangeBg" />
            </button>
          </li>
        ))}
      </ul>
      {isLoading && <Loader height={"100px"} />}
      <Pagination
        data={data?.data_count}
        pageNo={currentPage}
        setPageNo={setCurrentPage}
        perPage={perPage}
        setPerPage={setPerPage}
      />
    </React.Fragment>
  );
};

export default Make;
