import React, { useState } from "react";
import { Box, Image, Text, Flex } from "@theme-ui/components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faArrowRight,
  faCaretLeft,
  faCaretRight,
} from "@fortawesome/free-solid-svg-icons";

const TripPictures = ({ data }) => {
  //   console.log(data);

  const [currentIdx, setCurrentIdx] = useState(0);

  const result =
    data &&
    Object.keys(data)
      .filter((key) => key !== "datePictureTaken")
      .map((key) => ({
        key: key,
        ...data[key],
      }));

  //   console.log(result);

  const increaseIndex = () => {
    setCurrentIdx((prevIdx) => {
      if (prevIdx < result?.length - 1) {
        return prevIdx + 1;
      }
      return prevIdx; // Return the current index if it is already at the last item
    });
  };

  const decreaseIndex = () => {
    setCurrentIdx((prevIdx) => {
      if (prevIdx > 0) {
        return prevIdx - 1;
      }
      return prevIdx; // Return the current index if it is already at 0
    });
  };

  if (!result) return <Box className="p-5 border border-gray-200"></Box>;

  return (
    <Box className="p-5 border border-gray-200">
      <Text className="text-lg uppercase">Trip Pictures</Text>
      <Flex sx={{ marginTop: "10px" }} className="gap-5">
        <button onClick={decreaseIndex}>
          <FontAwesomeIcon icon={faArrowLeft} />
        </button>
        <div className="h-[300px] w-full">
          <img
            className="h-full w-full object-contain"
            src={result[currentIdx]?.url}
          />
        </div>
        <button onClick={increaseIndex}>
          <FontAwesomeIcon icon={faArrowRight} />
        </button>
      </Flex>
      <Box className="uppercase w-full font-medium text-xl text-center mt-3">
        {result[currentIdx]?.key}
      </Box>
    </Box>
  );
};

export default TripPictures;
