import apiClient from "./api";

const client = apiClient();

export const dispute = {
  getOpenDispute: () =>
    client
      .get(`/api/v1/getOpenDispute?page=1&limit=500`)
      .then(({ data }) => data),

  acceptDispute: (id) =>
    client.post(`/api/v1/acceptOpenDispute/${id}`).then(({ data }) => data),

  getAcceptedDispute: () =>
    client
      .get(`/api/v1/getAssignedDispute?page=1&limit=500`)
      .then(({ data }) => data),

  getTripInfo: (id) =>
    client.get(`/api/v1/getTripInfo/${id}`).then(({ data }) => data),

  getTripRequest: (id) =>
    client.get(`/api/v1/getDayTripRequest/${id}`).then(({ data }) => data),

  getCustomerMessages: (id) =>
    client
      .get(`/api/v1/getHostCustomerMessages/${id}?limit=3&page=2`)
      .then(({ data }) => data),

  settleDispute: ({ id, payload }) =>
    client.post(`/api/v1/settlement/${id}`, payload).then(({ data }) => data),
};
