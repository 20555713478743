import apiClient from "./api";

const client = apiClient();

export const auth = {
  login: (payload) =>
    client.post(`/api/v1/login`, payload).then(({ data }) => data),

  getOtp: (payload) =>
    client.post(`/api/v1/forgotPassword`, payload).then(({ data }) => data),

  resetPassword: (payload) =>
    client.post(`/api/v1/resetPassword`, payload).then(({ data }) => data),
};
