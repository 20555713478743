import React from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { dispute } from "../../../api/dispute";
import { Box, Flex, Text } from "@theme-ui/components";
import { GoogleMap, Marker, Polyline } from "@react-google-maps/api";
import Loader from "../../../components/Loader/Loader";
import { convertDate } from "../../../helpers/convertDate";
import TripPictures from "./TripPictures";
import CarPictures from "./CarPictures";

const TripInfo = () => {
  const { complaintId } = useParams();

  const { data: tripData, isLoading } = useQuery(
    ["Trip Information", complaintId],
    async () => {
      const { data } = await dispute.getTripInfo(complaintId);
      return data;
    },
    { cacheTime: 30000, staleTime: 30000, refetchOnWindowFocus: false }
  );

  console.log(tripData);

  const mapStyles = {
    height: "150px",
    width: "100%",
    border: "1px solid #ccc",
    padding: "5px",
  };

  const SubInfo = ({ title, content }) => {
    return (
      <Box mb={3}>
        <Text mr={1} className="font-medium uppercase">
          {title}
        </Text>
        <Text className="capitalize">{content || "-"}</Text>
      </Box>
    );
  };

  if (isLoading) {
    return <Loader />;
  }

  const defaultCenter = {
    lat: tripData?.tripDetails?.pickUpLocation?.coordinates?.latitude,
    lng: tripData?.tripDetails?.pickUpLocation?.coordinates?.longitude,
  };

  const positions =
    tripData?.driversLocation?.map((trip) => ({
      lat: trip?.location?.coordinates?.latitude,
      lng: trip?.location?.coordinates?.longitude,
    })) || [];

  const calculateCenter = (positions) => {
    if (positions.length === 0) return { lat: 0, lng: 0 };

    const sum = positions.reduce(
      (acc, pos) => {
        return {
          lat: acc.lat + pos.lat,
          lng: acc.lng + pos.lng,
        };
      },
      { lat: 0, lng: 0 }
    );

    const avg = {
      lat: sum.lat / positions.length,
      lng: sum.lng / positions.length,
    };

    return avg;
  };

  const center = calculateCenter(positions);

  // console.log(defaultCenter);

  // console.log(positions);

  return (
    <div className="w-full mt-2 p-5 h-[75vh] overflow-y-scroll">
      <div
        className="w-full border border-slate-300 p-4"
        style={{ boxShadow: "3px 3px 1px 0 #023047" }}
      >
        <Flex
          sx={{
            flexDirection: ["column", "row"],
          }}
          className="w-full"
        >
          <Box sx={{ flex: 1 }}>
            <SubInfo
              title={"Trip Type:"}
              content={tripData?.tripDetails?.tripType}
            />
            <SubInfo
              title={"Pickup Time:"}
              content={tripData?.tripDetails?.pickUpTime}
            />
            <SubInfo
              title={"Pickup Location:"}
              content={tripData?.tripDetails?.pickUpLocation?.address}
            />
          </Box>
          <Box sx={{ flex: 1 }}>
            <SubInfo title={"Trip Date:"} content={tripData?.tripDate} />
            <SubInfo
              title={"Closing Time:"}
              content={tripData?.tripDetails?.closingTime}
            />
            <SubInfo
              title={"Other Dates:"}
              content={tripData?.tripDetails?.tripDate?.join(", ")}
            />
          </Box>
        </Flex>
        <Flex className="w-full">
          <GoogleMap
            mapContainerStyle={mapStyles}
            zoom={13}
            center={defaultCenter}
          >
            <Marker position={defaultCenter} />
          </GoogleMap>
        </Flex>

        <Flex
          sx={{
            flexDirection: ["column", "row"],
            marginTop: "10px",
          }}
          className="w-full"
        >
          <Box sx={{ flex: 1 }}>
            <SubInfo
              title={"ITINERARY:"}
              content={tripData?.tripDetails?.tripType}
            />
            <SubInfo
              title={"Daily Payout:"}
              content={`NGN ${tripData?.amountHeld?.toLocaleString()}`}
            />
          </Box>
          <Box sx={{ flex: 1 }}>
            <SubInfo
              title={"Car info:"}
              content={`${tripData?.vehicleId?.carDetails?.carMake} - ${tripData?.vehicleId?.carDetails?.carModel} (${tripData?.vehicleId?.carDetails?.carColor}), ${tripData?.vehicleId?.carDetails?.address}`}
            />
            <SubInfo
              title={"Host Payout:"}
              content={`NGN ${tripData?.hostPayOut?.toLocaleString()}`}
            />
          </Box>
        </Flex>
        <Box
          sx={{
            marginTop: "20px",
          }}
          className="w-full"
        >
          <SubInfo title={"Trip day"} content={` `} />
          <Box className="grid gap-2 md:grid-cols-2 grid-cols-1">
            <TripPictures
              data={tripData?.tripData?.customerConfirmsTrip?.tripPictures}
            />
            <CarPictures data={tripData?.vehicleId?.carImages} />
          </Box>
        </Box>
        <Box
          sx={{
            marginTop: "10px",
          }}
          className="w-full"
        >
          <SubInfo
            title={"Driver confirms Trip:"}
            content={`${tripData?.tripData?.startTrip?.driverId?.fName || ""} ${
              tripData?.tripData?.startTrip?.driverId?.lName || ""
            }, ${convertDate(tripData?.tripData?.startTrip?.startTime)}`}
          />
          <GoogleMap
            mapContainerStyle={mapStyles}
            zoom={13}
            center={{
              lat: tripData?.tripData?.startTrip?.location?.coordinates
                ?.latitude,
              lng: tripData?.tripData?.startTrip?.location?.coordinates
                ?.longitude,
            }}
          >
            <Marker
              position={{
                lat: tripData?.tripData?.startTrip?.location?.coordinates
                  ?.longitude,
                lng: tripData?.tripData?.startTrip?.location?.coordinates
                  ?.latitude,
              }}
            />
          </GoogleMap>
        </Box>
        {/* DRIVER ARRIVES */}
        <Box
          sx={{
            marginTop: "40px",
          }}
          className="w-full"
        >
          <SubInfo
            title={"Driver Arrives:"}
            content={`${
              tripData?.tripData?.driverArrives?.driverId?.fName || ""
            } ${
              tripData?.tripData?.driverArrives?.driverId?.lName || ""
            }, ${convertDate(tripData?.tripData?.driverArrives?.startTime)}`}
          />
          <GoogleMap
            mapContainerStyle={mapStyles}
            zoom={13}
            center={{
              lat: tripData?.tripData?.driverArrives?.location?.coordinates
                ?.latitude,
              lng: tripData?.tripData?.driverArrives?.location?.coordinates
                ?.longitude,
            }}
          >
            <Marker
              position={{
                lat: tripData?.tripData?.driverArrives?.location?.coordinates
                  ?.longitude,
                lng: tripData?.tripData?.driverArrives?.location?.coordinates
                  ?.latitude,
              }}
            />
          </GoogleMap>
        </Box>
        {/*CUSTOMER ARRIVES*/}
        <Box
          sx={{
            marginTop: "40px",
          }}
          className="w-full"
        >
          <SubInfo
            title={"Customer confirms trip:"}
            content={`${
              tripData?.tripData?.customerConfirmsTrip?.driverId?.fName || ""
            } ${
              tripData?.tripData?.customerConfirmsTrip?.driverId?.lName || ""
            }, ${convertDate(
              tripData?.tripData?.customerConfirmsTrip?.startTime
            )}`}
          />
          <GoogleMap
            mapContainerStyle={mapStyles}
            zoom={13}
            center={{
              lat: tripData?.tripData?.customerConfirmsTrip?.location
                ?.coordinates?.latitude,
              lng: tripData?.tripData?.customerConfirmsTrip?.location
                ?.coordinates?.longitude,
            }}
          >
            <Marker
              position={{
                lat: tripData?.tripData?.customerConfirmsTrip?.location
                  ?.coordinates?.longitude,
                lng: tripData?.tripData?.customerConfirmsTrip?.location
                  ?.coordinates?.latitude,
              }}
            />
          </GoogleMap>
        </Box>
        {/*ACTUAL START TIME*/}
        <Box
          sx={{
            marginTop: "40px",
          }}
          className="w-full"
        >
          <SubInfo
            title={"driver starts trip:"}
            content={`${
              tripData?.tripData?.actualStartTrip?.driverId?.fName || ""
            } ${
              tripData?.tripData?.actualStartTrip?.driverId?.lName || ""
            }, ${convertDate(tripData?.tripData?.actualStartTrip?.startTime)}`}
          />
          <GoogleMap
            mapContainerStyle={mapStyles}
            zoom={13}
            center={{
              lat: tripData?.tripData?.actualStartTrip?.location?.coordinates
                ?.latitude,
              lng: tripData?.tripData?.actualStartTrip?.location?.coordinates
                ?.longitude,
            }}
          >
            <Marker
              position={{
                lat: tripData?.tripData?.actualStartTrip?.location?.coordinates
                  ?.longitude,
                lng: tripData?.tripData?.actualStartTrip?.location?.coordinates
                  ?.latitude,
              }}
            />
          </GoogleMap>
        </Box>
        {/*DRIVER END TRIP*/}
        <Box
          sx={{
            marginTop: "40px",
          }}
          className="w-full"
        >
          <SubInfo
            title={"actual start trip:"}
            content={`${tripData?.tripData?.endTrip?.driverId?.fName || ""} ${
              tripData?.tripData?.endTrip?.driverId?.lName || ""
            }, ${convertDate(tripData?.tripData?.endTrip?.startTime)}`}
          />
          <GoogleMap
            mapContainerStyle={mapStyles}
            zoom={13}
            center={{
              lat: tripData?.tripData?.endTrip?.location?.coordinates?.latitude,
              lng: tripData?.tripData?.endTrip?.location?.coordinates
                ?.longitude,
            }}
          >
            <Marker
              position={{
                lat: tripData?.tripData?.endTrip?.location?.coordinates
                  ?.longitude,
                lng: tripData?.tripData?.endTrip?.location?.coordinates
                  ?.latitude,
              }}
            />
          </GoogleMap>
        </Box>
        {/*DRIVER's ROUTE*/}
        <Box
          sx={{
            marginTop: "40px",
          }}
          className="w-full"
        >
          <SubInfo title={"driver's location"} content={``} />
          <GoogleMap mapContainerStyle={mapStyles} zoom={13} center={center}>
            {positions.map((position, index) => (
              <Marker key={index} position={position} />
            ))}
            <Polyline
              path={positions}
              options={{
                strokeColor: "#FF0000",
                strokeOpacity: 1.0,
                strokeWeight: 2,
              }}
            />
          </GoogleMap>
        </Box>
      </div>
    </div>
  );
};

export default TripInfo;
