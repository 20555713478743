import React from "react";
import { useState, useContext, useEffect } from "react";
import useWindowDimensions from "../../../hooks/useWindowSize";
import { UserContext } from "../../../context/UserContext";
import { ManageStaffContext } from "../ManageStaffContext";
import Loader from "../../../components/Loader/Loader";
import { convertDate } from "../../../helpers/convertDate";
import { capitalizeText } from "../../../helpers/capitalizeText";
import { useNavigate } from "react-router-dom";

const Team = ({ refresh }) => {
  const user = useContext(UserContext);
  const navigate = useNavigate();

  const { data } = useContext(ManageStaffContext);

  const { teams } = data;

  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [lastPage, setLastPage] = useState("");
  const { width } = useWindowDimensions();

  // console.log({ teams });

  if (teams?.isLoading) {
    return <Loader />;
  }

  const handleViewTeams = (team) => {
    navigate(team?._id);
    sessionStorage.setItem("currentTeam", JSON.stringify(team));
  };

  return (
    <React.Fragment>
      <div className="p-2 gap-2 flex mt-2 mb-1">
        <input
          className="flex-1 border border-slate-300 bg-slate-100 block px-4 py-2 outline-none duration-200 focus:px-5 focus:border-orangeBg focus:ring-0 focus:bg-white"
          type="text"
          placeholder="Search Team..."
        />
        <select className="border border-slate-300 bg-slate-100 duration-200 outline-none focus:ring-0">
          <option selected disabled>
            Filter by Status
          </option>
          <option value={""}>All</option>
        </select>
      </div>
      <div className={width < 601 ? "overflow-x-scroll" : ""}>
        <table className="w-full border border-slate-300">
          <thead className="bg-darkBlue text-white">
            <tr>
              <th className="border border-darkBlue py-3 px-2">S/N</th>
              <th className="border border-darkBlue py-3 px-2">NAME</th>
              <th className="border border-darkBlue py-3 px-2">TEAM LEADER</th>
              <th className="border border-darkBlue py-3 px-2">DATE CREATED</th>
            </tr>
          </thead>
          <tbody className="text-center">
            {teams?.data?.map((team, index) => (
              <tr
                key={index}
                className="border border-slate-300 duration-200 cursor-pointer bg-slate-100 hover:bg-slate-200"
                onClick={() => handleViewTeams(team)}
              >
                <td className="py-3 px-2">{index + 1}</td>
                <td className="py-3 px-2">{team?.name}</td>
                <td className="py-3 px-2">
                  {team?.staff?.firstName} {team?.staff?.lastName}
                </td>
                <td className="py-3 px-2">{convertDate(team?.createdAt)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </React.Fragment>
  );
};

export default Team;
