import apiClient from "./api";

const client = apiClient();

export const approvals = {
  getApprovals: ({ type, sort }) =>
    client
      .get(
        `/api/v1/getApprovals/${type}${
          sort === "" ? "" : "?sort=approvalEligibleDate"
        }`
      )
      .then(({ data }) => data),

  approveDecline: ({ type, id, payload }) =>
    client
      .post(`/api/v1/approve/${type}/${id}`, payload)
      .then(({ data }) => data),
};
