import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useMutation } from "react-query";
import { dispute } from "../../../api/dispute";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";

const SettleDispute = ({ handleModal }) => {
  const { register, handleSubmit, control, watch } = useForm();
  const { complaintId } = useParams();
  const watchPenalty = watch("penalty");
  const watchCustomerRefund = watch("customerRefund");
  const watchIssueHostStrike = watch("issueHostStrike");
  const watchIssueCustomerStrike = watch("issueCustomerStrike");
  const watchIssueDriverStrike = watch("issueDriverStrike");
  const watchDeactivateHostAccount = watch("deactivateHostAccount");

  const { mutateAsync } = useMutation(dispute.settleDispute);

  const onSubmit = async (payload) => {
    console.log(payload);
    toast.loading("Processing");
    try {
      const data = await mutateAsync({ id: complaintId, payload });
      toast.remove();
      toast.success(data?.message);
      setTimeout(() => {
        handleModal();
      }, 1000);
    } catch ({ response }) {
      console.log(response);
      toast.remove();
      toast.error(response?.data?.message);
    }
  };

  return (
    <div className="w-full p-5">
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="w-full mx-auto bg-white"
      >
        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-semibold mb-2"
            htmlFor="penalty"
          >
            Has Penalty?
          </label>
          <select
            id="penalty"
            {...register("penalty", { required: true })}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          >
            <option value="no">No</option>
            <option value="yes">Yes</option>
          </select>
        </div>

        {watchPenalty === "yes" && (
          <>
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-semibold mb-2"
                htmlFor="customerRefund"
              >
                Customer Refund?
              </label>
              <select
                id="customerRefund"
                {...register("customerRefund")}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              >
                <option value="yes">Yes</option>
                <option value="no">No</option>
              </select>
            </div>

            {watchCustomerRefund === "yes" && (
              <>
                <div className="mb-4">
                  <label
                    className="block text-gray-700 text-sm font-semibold mb-2"
                    htmlFor="customerRefundPercentage"
                  >
                    Customer Refund Percentage
                  </label>
                  <input
                    id="customerRefundPercentage"
                    {...register("customerRefundPercentage")}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  />
                </div>

                <div className="mb-4">
                  <label
                    className="block text-gray-700 text-sm font-semibold mb-2"
                    htmlFor="customerRefundReason"
                  >
                    Customer Refund Reason
                  </label>
                  <input
                    type="text"
                    id="customerRefundReason"
                    {...register("customerRefundReason")}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  />
                </div>
              </>
            )}

            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-semibold mb-2"
                htmlFor="issueHostStrike"
              >
                Issue Host Strike?
              </label>
              <select
                id="issueHostStrike"
                {...register("issueHostStrike")}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              >
                <option value="yes">Yes</option>
                <option value="no">No</option>
              </select>
            </div>

            {watchIssueHostStrike === "yes" && (
              <div className="mb-4">
                <label
                  className="block text-gray-700 text-sm font-semibold mb-2"
                  htmlFor="hostStrikeReason"
                >
                  Host Strike Reason
                </label>
                <input
                  type="text"
                  id="hostStrikeReason"
                  {...register("hostStrikeReason")}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
            )}

            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-semibold mb-2"
                htmlFor="issueCustomerStrike"
              >
                Issue Customer Strike?
              </label>
              <select
                id="issueCustomerStrike"
                {...register("issueCustomerStrike")}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              >
                <option value="yes">Yes</option>
                <option value="no">No</option>
              </select>
            </div>

            {watchIssueCustomerStrike === "yes" && (
              <div className="mb-4">
                <label
                  className="block text-gray-700 text-sm font-semibold mb-2"
                  htmlFor="customerStrikeReason"
                >
                  Customer Strike Reason
                </label>
                <input
                  type="text"
                  id="customerStrikeReason"
                  {...register("customerStrikeReason")}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
            )}

            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-semibold mb-2"
                htmlFor="issueDriverStrike"
              >
                Issue Driver Strike
              </label>
              <select
                id="issueDriverStrike"
                {...register("issueDriverStrike")}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              >
                <option value="yes">Yes</option>
                <option value="no">No</option>
              </select>
            </div>

            {watchIssueDriverStrike === "yes" && (
              <div className="mb-4">
                <label
                  className="block text-gray-700 text-sm font-semibold mb-2"
                  htmlFor="driverStrikeReason"
                >
                  Driver Strike Reason
                </label>
                <input
                  type="text"
                  id="driverStrikeReason"
                  {...register("driverStrikeReason")}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
            )}

            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-semibold mb-2"
                htmlFor="deactivateHostAccount"
              >
                Deactivate Host Account?
              </label>
              <select
                id="deactivateHostAccount"
                {...register("deactivateHostAccount")}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              >
                <option value="yes">Yes</option>
                <option value="no">No</option>
              </select>
            </div>

            {watchDeactivateHostAccount === "yes" && (
              <div className="mb-4">
                <label
                  className="block text-gray-700 text-sm font-semibold mb-2"
                  htmlFor="hostAccountDeactivationReason"
                >
                  Host Account Deactivation Reason
                </label>
                <input
                  type="text"
                  id="hostAccountDeactivationReason"
                  {...register("hostAccountDeactivationReason")}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
            )}
          </>
        )}
        <div className="flex items-center justify-between">
          <button
            type="submit"
            className="bg-darkBlue w-full text-white font-medium py-3 mt-4 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default SettleDispute;
