import React from "react";
import { ErrorOutlined } from "@material-ui/icons";

const Empty = () => {
  return (
    <div className="py-10 flex items-center justify-center gap-1">
      No Data <ErrorOutlined className="text-orangeBg" />
    </div>
  );
};

export default Empty;
