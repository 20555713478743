import React, { useMemo, useState } from "react";
import { Box, Text, Image } from "@theme-ui/components";
import {
  ErrorOutlined,
  ArrowLeft,
  ArrowRight,
  Close,
} from "@material-ui/icons";

const CarImage = ({ car }) => {
  const [activeImgIndex, setActiveImgIndex] = useState(0);

  let imgViews = useMemo(
    () => [
      { id: 1, slug: "driverSide", title: "Driver Side" },
      { id: 2, slug: "frontSeat", title: "Front Seat" },
      { id: 3, slug: "frontView", title: "Front View" },
      { id: 4, slug: "passengerSide", title: "Passenger Side" },
      { id: 5, slug: "rearSeat", title: "Rear Seat" },
      { id: 6, slug: "rearView", title: "Rear View" },
    ],
    []
  );

  const handleForward = () => {
    if (activeImgIndex !== 5) {
      setActiveImgIndex(activeImgIndex + 1);
    }
  };

  const handleBackward = () => {
    if (activeImgIndex !== 0) {
      setActiveImgIndex(activeImgIndex - 1);
    }
  };

  return (
    <React.Fragment>
      <Image
        sx={{ height: "150px", border: "1px dotted #ccc" }}
        className="object-contain"
        src={car?.carImages?.[imgViews[activeImgIndex].slug]?.url}
        variant="avatar"
        alt=""
      ></Image>
      <h1 className="font-medium w-full text-center rounded-lg py-1 uppercase">
        {imgViews[activeImgIndex]?.title}
      </h1>
      <div className="w-full flex items-center justify-between p-2 my-2">
        <button
          onClick={handleBackward}
          className="text-darkBlue font-extrabold text-2xl border border-slate-300 rounded-lg w-12 flex items-center justify-center h-12"
          style={{ boxShadow: "0 0 3px 0 #ccc" }}
        >
          <ArrowLeft />
        </button>
        <button
          onClick={handleForward}
          className="text-darkBlue font-extrabold text-2xl border border-slate-300 rounded-lg w-12 flex items-center justify-center h-12"
          style={{ boxShadow: "0 0 3px 0 #ccc" }}
        >
          <ArrowRight />
        </button>
      </div>
    </React.Fragment>
  );
};

export default CarImage;
