import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretLeft, faCaretRight } from "@fortawesome/free-solid-svg-icons";

const perPageOptions = [5, 10, 15, 25, 35, 45];

const Pagination = ({ pageNo, setPageNo, data, perPage, setPerPage }) => {
  const [currentPageNo, setCurrentPageNo] = useState(0);
  //   console.log({ data });
  const length = data?.lastPage || null;

  //   console.log(length);

  //   console.log(perPage);

  const pages = Array.from({ length }, (_, index) => index + 1);
  const chunkPages = [];

  //if size of pages is more than 10, then break pages it sub-arrays of 10 items
  if (pages.length > 10) {
    const pageSize = 10;
    for (let i = 0; i < pages.length; i += pageSize) {
      const chunk = pages.slice(i, i + pageSize);
      chunkPages.push(chunk);
    }
  } else {
    chunkPages.splice(0, chunkPages.length);
  }

  // console.log({ chunkPages });

  // console.log(chunkPages.length);

  //   console.log({ pages });
  const handlePerPage = (e) => {
    setPageNo(1);
    setPerPage(parseInt(e.target.value));
    setCurrentPageNo(0);
  };

  // console.log(pageNo);

  // console.log(currentPageNo);

  if (!data || data?.lastPage === 1) {
    return (
      <div className="mt-6 mb-2 w-full flex items-center gap-4 justify-end">
        {perPage && (
          <select
            value={perPage}
            className="text-sm border border-slate-300 py-2 text-slate-500 focus:ring-0 duration-200 outline-none focus:border-darkBlue"
            onChange={handlePerPage}
          >
            {perPageOptions.map((perPageOption) => (
              <option key={perPageOption} value={perPageOption}>
                {perPageOption} Per Page
              </option>
            ))}
          </select>
        )}
      </div>
    );
  }

  //   console.log({ data });

  return (
    <div className="mt-6 mb-2 w-full flex items-center gap-4 justify-between">
      <div className="flex items-center justify-center gap-4">
        {/*BACK BUTTON */}
        <button
          className={`text-xl flex items-center justify-center h-10 ${
            chunkPages.length > 0 && currentPageNo !== 0
              ? "text-darkBlue"
              : "text-slate-300"
          }`}
          disabled={chunkPages.length > 0 && currentPageNo !== 0 ? false : true}
          onClick={() => {
            setCurrentPageNo(currentPageNo - 1);
            setPageNo(chunkPages[currentPageNo - 1][0]);
          }}
        >
          <FontAwesomeIcon icon={faCaretLeft} />
        </button>

        {/*PAGINATION BUTTONS */}
        {chunkPages.length < 1 &&
          pages?.map((page) => (
            <button
              className={
                page === data?.currentPage ? "text-orangeBg" : "text-darkBlue"
              }
              key={page}
              onClick={() => {
                setPageNo(page);
              }}
            >
              {page}
            </button>
          ))}
        {chunkPages.length > 0 &&
          chunkPages[currentPageNo]?.map((page) => (
            <button
              className={
                page === data?.currentPage ? "text-orangeBg" : "text-darkBlue"
              }
              key={page}
              onClick={() => {
                setPageNo(page);
              }}
            >
              {page}
            </button>
          ))}
        {chunkPages.length > 0 &&
          pageNo !== data?.lastPage &&
          chunkPages[currentPageNo] && (
            <div className="flex items-center justify-center gap-1">
              <p>...</p>
              <button
                className={
                  pageNo === data?.lastPage ? "text-orangeBg" : "text-darkBlue"
                }
                onClick={() => {
                  setCurrentPageNo(chunkPages.length - 1);
                  setPageNo(data?.lastPage);
                }}
              >
                {data?.lastPage}
              </button>
            </div>
          )}
        {/*FORWARD BUTTON */}
        <button
          className={`text-xl flex items-center justify-center h-10 ${
            chunkPages.length > 0 && currentPageNo !== chunkPages.length - 1
              ? "text-darkBlue"
              : "text-slate-300"
          }`}
          disabled={
            chunkPages.length > 0 && currentPageNo !== chunkPages.length - 1
              ? false
              : true
          }
          onClick={() => {
            setCurrentPageNo(currentPageNo + 1);
            setPageNo(chunkPages[currentPageNo + 1][0]);
          }}
        >
          <FontAwesomeIcon icon={faCaretRight} />
        </button>
      </div>
      {perPage && (
        <select
          value={perPage}
          className="text-sm border border-slate-300 text-slate-500 py-2 focus:ring-0 duration-200 outline-none focus:border-darkBlue"
          onChange={handlePerPage}
        >
          {perPageOptions.map((perPageOption) => (
            <option key={perPageOption} value={perPageOption}>
              {perPageOption} Per Page
            </option>
          ))}
        </select>
      )}
    </div>
  );
};

export default Pagination;
