import React from "react";

export const JointPermissionGuard = ({ requiredPermissions, children }) => {
  const permissions = JSON.parse(sessionStorage.getItem("user"))?.permissions;

  //   console.log(permissions);
  // Check if all required permissions are present
  const hasPermission = requiredPermissions.every((permission) =>
    permissions.some((p) => p === permission)
  );
  //   console.log(hasPermission);

  // Render children if permission is granted, otherwise render null
  return hasPermission ? children : null;
};

const PermissionGuard = ({ requiredPermissions, children }) => {
  const permissions = JSON.parse(sessionStorage.getItem("user"))?.permissions;

  //   console.log(permissions);
  // Check if all required permissions are present
  const hasPermission = requiredPermissions.some((permission) =>
    permissions.some((p) => p === permission)
  );
  //   console.log(hasPermission);

  // Render children if permission is granted, otherwise render null
  return hasPermission ? children : null;
};

export default PermissionGuard;
