import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useQuery, useMutation } from "react-query";
import { Box, Text, Image, Flex } from "@theme-ui/components";
import { vendors } from "../../../api/vendors";
import { ErrorOutlined } from "@material-ui/icons";
import VerticalMenu from "../../../components/VerticalMenu/VerticalMenu";
import { vertButtonClass } from "../../ManageStaff/Style";
import Loader from "../../../components/Loader/Loader";
import Pagination from "../../../components/Pagination/Pagination";
import toast from "react-hot-toast";
import Ratings from "./Ratings";

const Reviews = () => {
  const { vendor_id: id } = useParams();

  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(5);

  const { mutateAsync } = useMutation(vendors.deleteReview);

  const {
    data: reviews,
    isLoading,
    refetch,
  } = useQuery(
    ["selected Reviews", { id, currentPage, perPage }],
    ({ queryKey }) => vendors.getReviews(queryKey[1]),
    {
      cacheTime: 30000,
      staleTime: 30000,
      refetchOnWindowFocus: true,
      select: (data) => data,
    }
  );

  const DeleteReview = async (review_id) => {
    if (confirm("Are you sure you want to delete this review?")) {
      toast.loading("Processing...");
      try {
        const data = await mutateAsync(review_id);
        // console.log(data);
        toast.remove();
        toast.success(data?.message);
        setTimeout(() => {
          location.reload();
        }, 1000);
      } catch ({ response }) {
        // console.log(response);
        toast.remove();
        toast.error(response?.data?.message);
      }
    }
  };

  if (reviews?.data?.length < 1) {
    return (
      <Box sx={{ width: "100%", textAlign: "center" }} mt={3}>
        <ErrorOutlined className="mr-1 text-orangeBg" />
        <Text>No data</Text>
      </Box>
    );
  }

  // console.log(reviews);

  return (
    <React.Fragment>
      {isLoading ? (
        <Loader />
      ) : (
        <Box
          sx={{
            marginTop: "10px",
          }}
          className="bg-slate-200 p-10 overflow-y-scroll border border-gray-400 gap-[20px] w-full max-h-[500px] grid grid-cols-1 md:grid-cols-3"
        >
          {reviews?.data?.map((review) => (
            <Box
              key={review?._id}
              className=" flex flex-col p-3 gap-[10px] rounded-md border border-slate-100 shadow bg-white"
            >
              <Image
                sx={{ height: "150px", border: "1px dotted #ccc" }}
                className="object-contain"
                src={review?.customerId?.profilePicture?.url}
                variant="avatar"
                alt=""
              ></Image>
              <Text className="font-medium uppercase">
                {review?.customerId?.fName}
              </Text>
              <Text className="border border-slate-100 bg-slate-100 rounded-md p-2">
                {review?.review}
              </Text>
              <Flex>
                <Text className="ml-1 uppercase">Trip Rating:</Text>
                <Ratings rating={parseInt(review?.rating)} />
              </Flex>
              <Flex className="w-full flex items-center justify-between">
                <Flex>
                  <Text className="ml-1 uppercase">Driver Rating:</Text>
                  <Ratings rating={parseInt(review?.driverRating)} />
                </Flex>
                <VerticalMenu isBackground>
                  <div className="">
                    <button
                      onClick={() => DeleteReview(review?._id)}
                      className={vertButtonClass}
                    >
                      Delete Review
                    </button>
                  </div>
                </VerticalMenu>
              </Flex>
            </Box>
          ))}
        </Box>
      )}
      <Pagination
        data={reviews?.data_count}
        pageNo={currentPage}
        setPageNo={setCurrentPage}
        perPage={perPage}
        setPerPage={setPerPage}
      />
    </React.Fragment>
  );
};

export default Reviews;
