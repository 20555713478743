import React from "react";
import { useState, useEffect, useCallback } from "react";
import "./ManageStaff.scss";
import CenteredModal from "../../components/Modal/CenteredModal";
import Staff from "./pages/Staff";
import Team from "./pages/Team";
import StaffInfo from "./pages/StaffInfo";
import TeamInfo from "./pages/TeamInfo";
import AddStaff from "./pages/AddStaff";
import AddTeam from "./pages/AddTeam";
import AddPermission from "./pages/AddPermission";
import { Close, Add, Search } from "@material-ui/icons";
import { Routes, Route, Link, useLocation, Navigate } from "react-router-dom";
import { api } from "../../api";
import { staff } from "../../api/staff";
import { useQuery } from "react-query";
import { ManageStaffContext } from "./ManageStaffContext";
import { useDebounce } from "../../hooks/useDebounce";
import PermissionGuard from "../../components/PermissionGuard/PermissionGuard";

const pages = [
  { id: 1, title: "STAFFS", path: "staffs" },
  // { id: 2, title: "TEAMS", path: "teams" },
];

const ManageStaff = () => {
  const [modal, showModal] = useState(false);
  const [permissionModal, showPermissionModal] = useState(false);

  const [search, setSearch] = useState("");
  const [status, setStatus] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(5);

  // console.log(perPage);

  const user = JSON.parse(sessionStorage.getItem("user"));

  const [data, setData] = useState({});

  const location = useLocation();

  const handleModal = () => {
    showModal(!modal);
  };

  const handlePermissionModal = () => {
    showPermissionModal(!permissionModal);
  };

  const handleStatus = (e) => {
    setStatus(e.target.value);
  };

  const debouncedSearch = useDebounce((value) => {
    setCurrentPage(1);
    setSearch(value);
  }, 1500); // Adjust the delay as needed (in milliseconds)

  const handleSearch = useCallback(
    (e) => {
      const value = e.target.value;
      setSearch(value);
      debouncedSearch(value);
    },
    [debouncedSearch]
  );

  const { data: prePermissions } = useQuery(
    ["Permissions"],
    async () => {
      const { data } = await staff.getPermissions();
      return data;
    },
    { cacheTime: 30000, staleTime: 30000, refetchOnWindowFocus: true }
  );

  // console.log(prePermissions);

  const {
    data: staffData,
    isLoading: loadingStaffs,
    isError: errStaffs,
    isFetching: fetchingStaffs,
    refetch: refetchStaffs,
  } = useQuery(
    ["Staff", search, status, currentPage, perPage],
    () => {
      return staff.getStaff({ search, status, currentPage, perPage });
    },
    {
      cacheTime: 30000,
      staleTime: 30000,
      select: (data) => data,
      refetchOnWindowFocus: true,
    }
  );

  // console.log(staffData);

  // console.log({ teamData });

  useEffect(() => {
    setData((prev) => {
      return {
        ...prev,
        data: staffData?.data,
        isLoading: loadingStaffs,
        isError: errStaffs,
        metaData: staffData?.metaData,
      };
    });
  }, [staffData, loadingStaffs, errStaffs, fetchingStaffs]);

  return (
    <ManageStaffContext.Provider value={{ data, setData }}>
      <div>
        <div
          className="flex items-end justify-between pb-2 my-4"
          style={{ borderBottom: "1px solid #023047" }}
        >
          <h1 className="text-2xl font-medium text-darkBlue">Manage Staff</h1>
          <div className="flex items-center justify-end gap-2">
            <PermissionGuard requiredPermissions={["admin"]}>
              <button
                className="px-5 mr-2 py-2 mb-1 border border-darkBlue font-medium text-slate-700 flex items-center gap-1"
                onClick={handlePermissionModal}
              >
                ADD PERMISSION
              </button>
            </PermissionGuard>
            <PermissionGuard requiredPermissions={["admin", "createUsers"]}>
              <button
                className="px-5 mr-2 py-2 mb-1 border border-darkBlue font-medium text-slate-700 flex items-center gap-1"
                onClick={handleModal}
              >
                ADD STAFF
              </button>
            </PermissionGuard>
          </div>
        </div>
        <Routes>
          <Route path="/" element={<Navigate to="staffs" />} />
          <Route
            path="/staffs"
            element={
              <Staff
                setSearch={setSearch}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                handleStatus={handleStatus}
                perPage={perPage}
                setPerPage={setPerPage}
              />
            }
          />
          <Route path="/teams" element={<Team />} />
          <Route
            path="/staffs/:id"
            element={
              <StaffInfo refetch={refetchStaffs} permissions={prePermissions} />
            }
          />
          <Route path="teams/:id" element={<TeamInfo />} />
        </Routes>
      </div>
      {/*Add Staff/Team Modal */}
      <CenteredModal
        title={location.pathname.includes("staffs") ? "ADD STAFF" : "ADD TEAM"}
        open={modal}
        setOpen={handleModal}
        closeButtonLabel={<Close />}
      >
        {location.pathname.includes("staffs") ? (
          <AddStaff
            hideModal={handleModal}
            prePermissions={prePermissions}
            refetch={refetchStaffs}
          />
        ) : (
          <AddTeam hideModal={handleModal} />
        )}
      </CenteredModal>
      <CenteredModal
        title={"ADD PERMISSION"}
        open={permissionModal}
        setOpen={handlePermissionModal}
        closeButtonLabel={<Close />}
      >
        <AddPermission hideModal={handlePermissionModal} />
      </CenteredModal>
    </ManageStaffContext.Provider>
  );
};

export default ManageStaff;
