import React from "react";
import { useParams } from "react-router-dom";
import { dispute } from "../../../api/dispute";
import { useQuery } from "react-query";
import useWindowDimensions from "../../../hooks/useWindowSize";
import Loader from "../../../components/Loader/Loader";

const TripRequest = () => {
  const { complaintId } = useParams();
  const { width } = useWindowDimensions();

  const { data: tripRequest, isLoading } = useQuery(
    ["Trip Request", complaintId],
    async () => {
      const { data } = await dispute.getTripRequest(complaintId);
      return data;
    },
    { cacheTime: 30000, staleTime: 30000, refetchOnWindowFocus: false }
  );

  // console.log(tripRequest);

  return (
    <div className={`w-full mt-2 ${width < 601 ? "overflow-x-scroll" : ""}`}>
      <table
        className="w-full border border-slate-300"
        style={{ minWidth: "600px" }}
      >
        <thead className="text-white">
          <tr className="bg-darkBlue rounded-md">
            <th className="border border-darkBlue font-semibold py-3 px-2">
              TYPE
            </th>
            <th className="border border-darkBlue font-semibold py-3 px-2">
              STATUS
            </th>
            <th className="border border-darkBlue font-semibold py-3 px-2">
              HOST APPROVAL
            </th>
            <th className="border border-darkBlue font-semibold py-3 px-2">
              AMOUNT PAID
            </th>
            <th className="border border-darkBlue font-semibold py-3 px-2">
              HOST PAYOUT
            </th>
          </tr>
        </thead>

        <tbody className="text-center">
          {tripRequest?.map((item, index) => (
            <tr
              className="border border-slate-300 flex-1 bg-slate-50 w-full duration-200 cursor-pointer"
              key={index}
              // onClick={handleViewDispute(item?._id)}
            >
              <td className="py-3 px-2 capitalize">{item?.requestType}</td>
              <td className="py-3 px-2 capitalize">{item?.status}</td>
              <td className="py-3 px-2 capitalize">{item?.hostApproval}</td>
              <td className="py-3 px-2 capitalize">{item?.amountPaid}</td>
              <td className="py-3 px-2 capitalize">{item?.hostPayout}</td>
            </tr>
          ))}
        </tbody>
      </table>
      {isLoading && <Loader />}
    </div>
  );
};

export default TripRequest;
