import React, { useContext } from "react";
import { ArrowBack } from "@material-ui/icons";
import useWindowDimensions from "../../../hooks/useWindowSize";
import { useNavigate } from "react-router-dom";
import { capitalizeText } from "../../../helpers/capitalizeText";
import { useMutation } from "react-query";
import { useForm } from "react-hook-form";
import { ManageStaffContext } from "../ManageStaffContext";
import { api } from "../../../api";
import toast from "react-hot-toast";

const TeamInfo = ({ refetch }) => {
  const { width } = useWindowDimensions();
  const { register, handleSubmit } = useForm();
  const { data } = useContext(ManageStaffContext);
  const { mutateAsync } = useMutation(api.updateTeam);

  const { staffs } = data;

  const user = JSON.parse(sessionStorage.getItem("user"));
  const currentTeam = JSON.parse(sessionStorage.getItem("currentTeam"));

  const navigate = useNavigate();

  const handleViewTeams = () => {
    navigate(`/${user?._id}/manage-staff/teams`);
  };

  const updateTeam = async (values) => {
    toast.loading("Updating...");
    try {
      const data = await mutateAsync({
        teamID: currentTeam?._id,
        payload: values,
      });
      toast.remove();
      toast.success(data?.message);
      refetch();
    } catch ({ response }) {
      toast.remove();
      toast.error(response?.data?.message);
    }
  };

  return (
    <div>
      <button
        onClick={handleViewTeams}
        className="text-sm flex items-center justify-center h-12 w-12 p-2 border border-slate-200 bg-transparent text-darkBlue rounded-full duration-200 hover:bg-slate-200 my-2"
      >
        <ArrowBack className="text-sm" />
      </button>
      <form
        className="p-3 grid grid-cols-1 md:grid-cols-3 sm:grid-cols-2 gap-4 border border-slate-300"
        onSubmit={handleSubmit(updateTeam)}
      >
        <div className="flex items-start flex-col justify-center gap-1">
          <p>Team Name</p>
          <input
            className="w-full flex-1 border border-slate-300 block px-4 py-2 rounded-md outline-none bg-slate-100 duration-200 focus:px-5 focus:border-orangeBg focus:bg-white focus:ring-0"
            type="text"
            {...register("name", {
              required: true,
            })}
            defaultValue={currentTeam?.name}
          />
        </div>
        <div className="flex items-start flex-col justify-center gap-1">
          <p>Team Leader</p>
          <select
            className="w-full flex-1 border border-slate-300 block px-4 py-2 rounded-md outline-none bg-slate-100 duration-200 focus:px-5 focus:border-orangeBg focus:bg-white focus:ring-0"
            {...register("staffId", {
              required: true,
            })}
          >
            {staffs?.data?.map((staff, index) => (
              <option key={index} value={staff?.staff?.staffId}>
                {staff?.staff?.firstName} {staff?.staff?.lastName}
              </option>
            ))}
          </select>
        </div>
        <div className="flex items-start flex-col justify-center gap-1">
          <p>Status</p>
          <input
            className="w-full flex-1 border border-slate-300 block px-4 py-2 rounded-md outline-none bg-slate-100 duration-200 focus:px-5 focus:border-orangeBg focus:bg-white focus:ring-0"
            disabled
            type="text"
            placeholder={capitalizeText(currentTeam?.staff?.status)}
          />
        </div>
        <div>
          <button
            className=" mt-5 px-5 py-2 border border-orangeBg bg-orangeBg font-bold text-white rounded duration-100 hover:text-darkBlue hover:bg-orange-200 hover:border-orange-200"
            type="submit"
          >
            UPDATE TEAM
          </button>
        </div>
      </form>

      {/* Account summary */}

      <h1 className="text-darkBlue mt-10 mb-2 font-semibold">
        ACCOUNT SUMMARY
      </h1>
      <div className={width < 601 ? "overflow-x-scroll" : ""}>
        <table
          className="w-full border border-slate-300"
          style={{ minWidth: "600px" }}
        >
          <thead className="bg-darkBlue text-white">
            <tr>
              <th className="border border-darkBlue py-3 px-2">ID</th>
              <th className="border border-darkBlue py-3 px-2">NAME</th>
              <th className="border border-darkBlue py-3 px-2">UPDATE</th>
              <th className="border border-darkBlue py-3 px-2">DATE</th>
            </tr>
          </thead>
          <tbody className="text-center">
            <tr className="border border-slate-300 duration-200 bg-slate-100">
              <td className="py-3 px-2">1401</td>
              <td className="py-3 px-2">Team A</td>
              <td className="py-3 px-2">Activate Account</td>
              <td className="py-3 px-2">27th May, 2022</td>
            </tr>
            <tr className="border border-slate-300 duration-200 bg-slate-100">
              <td className="py-3 px-2">1401</td>
              <td className="py-3 px-2">Team B</td>
              <td className="py-3 px-2">Role</td>
              <td className="py-3 px-2">27th May, 2022</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TeamInfo;
