import React, { useState, useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

// custom debounce fn
const useDebounce = (fn, delay) => {
  let timeout;

  // return function that takes arg and apply to the function
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      fn.apply(this, args);
    }, delay);
  };
};

const Search = ({ setSearch, setCurrentPage }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearch = useDebounce((value) => {
    setCurrentPage(1);
    setSearch(value);
  }, 1500); // Adjust the delay as needed (in milliseconds)

  const handleSearch = useCallback(
    (e) => {
      const value = e.target.value;
      setSearchTerm(value);
      debouncedSearch(value);
    },
    [debouncedSearch]
  );

  return (
    <div className="w-full relative">
      <FontAwesomeIcon
        icon={faSearch}
        className="absolute text-sm"
        style={{ left: "8px", top: "11px" }}
      />
      <input
        type="search"
        placeholder="Search..."
        className="w-full pl-7 border border-slate-300 bg-slate-100 block focus:ring-0 px-4 py-2 outline-none duration-200 focus:pl-8 focus:border-orangeBg focus:bg-white"
        value={searchTerm}
        onChange={handleSearch}
      />
    </div>
  );
};

export default Search;
