import apiClient from "./api";

const client = apiClient();

export const customers = {
  getCustomers: ({ search, currentPage, perPage }) =>
    client
      .get(
        `/api/v1/getCustomers?limit=${perPage}&page=${currentPage}&search=${search}`
      )
      .then(({ data }) => data),

  getCustomer: (id) =>
    client.get(`/api/v1/getCustomer/${id}`).then(({ data }) => data),

  getHoldingAmount: (id) =>
    client
      .get(`/api/v1/customerAmountInHolding/${id}`)
      .then(({ data }) => data),

  getCustomerTransactions: ({ id, perPage, currentPage }) =>
    client
      .get(
        `/api/v1/getCustomerTransactions/${id}?limit=${perPage}&page=${currentPage}`
      )
      .then(({ data }) => data),

  getCustomerTripHistory: ({ id, perPage, currentPage }) =>
    client
      .get(
        `/api/v1/getCustomerTripHistory/${id}?limit=${perPage}&page=${currentPage}`
      )
      .then(({ data }) => data),

  activateDeactiveAccount: ({ id, payload }) =>
    client
      .post(`/api/v1/activateAccount/${id}`, payload)
      .then(({ data }) => data),

  issueStrike: ({ id, payload }) =>
    client.post(`/api/v1/issueStrike/${id}`, payload).then(({ data }) => data),

  getAccountSummary: ({ id, perPage, currentPage }) =>
    client
      .get(
        `/api/v1/getAccountSummary/${id}?limit=${perPage}&page=${currentPage}`
      )
      .then(({ data }) => data),
};
