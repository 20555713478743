import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { Box, Text, Image } from "@theme-ui/components";
import { ErrorOutlined, Close } from "@material-ui/icons";
import { vendors } from "../../../api/vendors";
import Loader from "../../../components/Loader/Loader";
import Pagination from "../../../components/Pagination/Pagination";
import useWindowDimensions from "../../../hooks/useWindowSize";
import { convertDate } from "../../../helpers/convertDate";

const Transactions = () => {
  const { vendor_id: id } = useParams();

  const { width } = useWindowDimensions();

  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(5);

  const { data: transactions, isLoading } = useQuery(
    ["selected Transactions", { id, currentPage, perPage }],
    ({ queryKey }) => vendors.getTransactions(queryKey[1]),
    {
      cacheTime: 30000,
      staleTime: 30000,
      refetchOnWindowFocus: true,
      select: (data) => data,
    }
  );

  //   console.log(transactions);

  if (transactions?.data?.length < 1) {
    return (
      <Box sx={{ width: "100%", textAlign: "center" }} mt={3}>
        <ErrorOutlined className="mr-1 text-orangeBg" />
        <Text>No data</Text>
      </Box>
    );
  }

  return (
    <React.Fragment>
      {isLoading ? (
        <Loader />
      ) : (
        <div
          className={width < 769 ? "overflow-x-scroll mt-[10px]" : "mt-[10px]"}
        >
          <table
            className="w-full border border-slate-300"
            style={{ minWidth: "768px" }}
          >
            <thead className="bg-darkBlue text-white">
              <tr>
                <th className="border border-darkBlue py-3 px-2">AMOUNT</th>
                <th className="border border-darkBlue py-3 px-2">DATE</th>
                <th className="border border-darkBlue py-3 px-2">TYPE</th>
                <th className="border border-darkBlue py-3 px-2">CATEGORY</th>
                <th className="border border-darkBlue py-3 px-2">STATUS</th>
                <th className="border border-darkBlue py-3 px-2">REFERENCE</th>
                <th className="border border-darkBlue py-3 px-2">GATEWAY</th>
              </tr>
            </thead>
            <tbody className="text-center">
              {transactions?.data?.map((data) => (
                <tr
                  key={data?._id}
                  className="border border-slate-300 duration-200 bg-slate-100"
                >
                  <td className="py-3 px-2">
                    {data?.transactionAmount?.currency}{" "}
                    {data?.transactionAmount?.amount?.toLocaleString()}
                  </td>
                  <td className="py-3 px-2">{convertDate(data?.createdAt)}</td>
                  <td className="py-3 px-2 capitalize">
                    {data?.transactiontype}
                  </td>
                  <td className="py-3 px-2 capitalize">{data?.category}</td>
                  <td className="py-3 px-2 capitalize">
                    {data?.transactionStatus}
                  </td>
                  <td className="py-3 px-2 capitalize">
                    {data?.paymentReference}
                  </td>
                  <td className="py-3 px-2 capitalize">
                    {data?.paymentGateway}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      <Pagination
        data={transactions?.data_count}
        pageNo={currentPage}
        setPageNo={setCurrentPage}
        perPage={perPage}
        setPerPage={setPerPage}
      />
    </React.Fragment>
  );
};

export default Transactions;
