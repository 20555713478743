import React from "react";

const AssignedTransactions = () => {
  return (
    <>
      <h1>Assigned Transactions</h1>
      <h2>Details</h2>
    </>
  );
};

export default AssignedTransactions;
