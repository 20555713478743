import React from "react";
import ratingLogo from "../../../assets/star.png";
import ratingGreyLogo from "../../../assets/grey_star.png";
import { Flex } from "@theme-ui/components";

const Star = () => {
  return <img src={ratingLogo} alt="Rating" width={15} height={15} />;
};

const GreyStar = () => {
  return <img src={ratingGreyLogo} alt="Rating" width={15} height={15} />;
};

const Ratings = ({ rating }) => {
  const renderRatingLogos = () => {
    switch (rating) {
      case 1:
        return (
          <Flex>
            <Star />
            <GreyStar />
            <GreyStar />
            <GreyStar />
            <GreyStar />
          </Flex>
        );
      case 2:
        return (
          <Flex>
            <Star />
            <Star />
            <GreyStar />
            <GreyStar />
            <GreyStar />
          </Flex>
        );
      case 3:
        return (
          <Flex>
            <Star />
            <Star />
            <Star />
            <GreyStar />
            <GreyStar />
          </Flex>
        );
      case 4:
        return (
          <Flex>
            <Star />
            <Star />
            <Star />
            <Star />
            <GreyStar />
          </Flex>
        );
      case 5:
        return (
          <Flex>
            <Star />
            <Star />
            <Star />
            <Star />
            <Star />
          </Flex>
        );
      default:
        return null;
    }
  };

  return <div>{renderRatingLogos()}</div>;
};

export default Ratings;
