import React from "react";
import { Routes, Route } from "react-router-dom";
import LongTermLeasing from "./LongTermLeasing";
import ViewLeasingItem from "./ViewLeasingItem";

function LeasingRoutes() {
  return (
    <Routes>
      <Route path="/" element={<LongTermLeasing />} />
      <Route path="/:leasing_id" element={<ViewLeasingItem />} />
    </Routes>
  );
}

export default LeasingRoutes;
