import React from "react";
import io, { Socket } from "socket.io-client";
import URL from "../../../url";

const HostChat = () => {

  const token = sessionStorage.getItem("userToken");

  useEffect(() => {
    let socket;

    if (token) {
      // console.log("ln42", token);
      socket = io(`${URL}`, {
        extraHeaders: {
          token,
        },
      });

      socket.on("connect", () => {
        toast.remove();
        toast.success("Connection successful..");
      });

      socket.on("STRIPE_IDENTITY", (data) => {
        console.log("Received stripe_identity event:", data);

        // if (data.status === "verified") {
        //   toast.remove();
        //   toast.success("Verification successful...");
        //   setTimeout(() => {
        //     handleNext();
        //   }, 800);
        // } else {
        //   toast.remove();
        //   toast.error("Unable to verify, please try again...");
        // }
      });

      socket.on("disconnect", () => {
        // toast.remove();
        // toast.success("Disconnected..");
        console.log("Disconnected from Socket.IO server");
      });

      socket.on("error", (error) => {
        console.error("Socket.IO error:", error);
      });
    }

    return () => {
      if (socket) {
        socket.disconnect();
      }
    };
  }, [token]);

  return <div>HostChat</div>;
};

export default HostChat;
