import React from "react";
import { useState, useContext, useEffect, useCallback } from "react";
import useWindowDimensions from "../../../hooks/useWindowSize";
import { UserContext } from "../../../context/UserContext";
import { ManageStaffContext } from "../ManageStaffContext";
import Loader from "../../../components/Loader/Loader";
import { convertDate } from "../../../helpers/convertDate";
import { capitalizeText } from "../../../helpers/capitalizeText";
import { useNavigate } from "react-router-dom";
// import { useDebounce } from "../../../hooks/useDebounce";
import Search from "../../../components/Search/Search";
import Pagination from "../../../components/Pagination/Pagination";

const Staff = ({
  currentPage,
  setCurrentPage,
  setSearch,
  handleStatus,
  perPage,
  setPerPage,
}) => {
  const user = useContext(UserContext);
  const navigate = useNavigate();

  const { data } = useContext(ManageStaffContext);

  // console.log(data);

  // const { staffs, teams } = data;

  const { width } = useWindowDimensions();

  // console.log({ data });

  // if (data?.isLoading) {
  //   return <Loader />;
  // }

  const handleViewStaff = (staff) => {
    navigate(staff?._id);
    sessionStorage.setItem("currentStaff", JSON.stringify(staff));
  };

  return (
    <>
      <div className="p-2 gap-2 flex mt-2 mb-1">
        <Search setCurrentPage={setCurrentPage} setSearch={setSearch} />
        <select
          onChange={handleStatus}
          className="border border-slate-300 bg-slate-100 outline-none focus:ring-0 focus:border-orangeBg duration-200 focus:bg-white"
        >
          <option selected disabled>
            Filter by Status
          </option>
          <option value={""}>All</option>
          <option value={"active"}>Active</option>
          <option value={"pending"}>Pending</option>
          <option value={"deactivated"}>De-activated</option>
        </select>
      </div>
      <div className={width < 601 ? "overflow-x-scroll" : ""}>
        <table
          className="w-full border border-slate-300"
          style={{ minWidth: "600px" }}
        >
          <thead className="text-white">
            <tr className="bg-darkBlue rounded-md">
              <th className="border border-darkBlue font-semibold py-3 px-2">
                FIRST NAME
              </th>
              <th className="border border-darkBlue font-semibold py-3 px-2">
                LAST NAME
              </th>
              <th className="border border-darkBlue font-semibold py-3 px-2">
                E-MAIL
              </th>
              <th className="border border-darkBlue font-semibold py-3 px-2">
                STATUS
              </th>
            </tr>
          </thead>

          <tbody className="text-center">
            {data &&
              data?.data?.map((staff, index) => (
                <tr
                  className="border border-slate-300 flex-1 bg-slate-50 w-full duration-200 cursor-pointer hover:bg-slate-200"
                  key={index}
                  onClick={() => handleViewStaff(staff)}
                >
                  <td className="py-3 px-2">{staff?.fName}</td>
                  <td className="py-3 px-2">{staff?.lName}</td>
                  <td className="py-3 px-2">{staff?.email}</td>
                  <td className="py-3 px-2">
                    <span
                      className={
                        staff?.status == "active"
                          ? "rounded border border-dotted border-darkBlue py-1 px-3 bg-slate-200"
                          : "rounded border border-dotted border-orangeBg py-1 px-3 bg-orange-100"
                      }
                    >
                      {capitalizeText(staff?.status)}
                    </span>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        {data?.isLoading && <Loader />}
        <Pagination
          data={data?.metaData}
          pageNo={currentPage}
          setPageNo={setCurrentPage}
          setPerPage={setPerPage}
          perPage={perPage}
        />
      </div>
    </>
  );
};

export default Staff;
