import apiClient from "./api";

const client = apiClient();

export const vendors = {
  getVendors: ({ search, currentPage, perPage }) =>
    client
      .get(
        `/api/v1/getVendors?limit=${perPage}&page=${currentPage}&search=${search}`
      )
      .then(({ data }) => data),

  getVendor: (id) =>
    client.get(`/api/v1/getVendor/${id}`).then(({ data }) => data),

  getHoldingAmount: (id) =>
    client.get(`/api/v1/amountInHolding/${id}`).then(({ data }) => data),

  getFleets: ({ id, perPage, currentPage }) =>
    client
      .get(`/api/v1/vendorFleet/${id}?limit=${perPage}&page=${currentPage}`)
      .then(({ data }) => data),

  reAssignCar: ({ id, payload }) =>
    client
      .post(`/api/v1/reAssignVehicle/${id}`, payload)
      .then(({ data }) => data),

  activateDeactiveAccount: ({ id, payload }) =>
    client
      .post(`/api/v1/activateAccount/${id}`, payload)
      .then(({ data }) => data),

  issueStrike: ({ id, payload }) =>
    client.post(`/api/v1/issueStrike/${id}`, payload).then(({ data }) => data),

  getDrivers: ({ id, perPage, currentPage }) =>
    client
      .get(`/api/v1/getDrivers/${id}?limit=${perPage}&page=${currentPage}`)
      .then(({ data }) => data),

  activateDeactiveDriverAccount: ({ id, payload }) =>
    client
      .post(`/api/v1/activateDriverAccount/${id}`, payload)
      .then(({ data }) => data),

  issueDriverStrike: ({ id, payload }) =>
    client
      .post(`/api/v1/issueDriverStrike/${id}`, payload)
      .then(({ data }) => data),

  getReviews: ({ id, perPage, currentPage }) =>
    client
      .get(`/api/v1/getReviews/${id}?limit=${perPage}&page=${currentPage}`)
      .then(({ data }) => data),

  deleteReview: (id) =>
    client.delete(`/api/v1/deleteReview/${id}`).then(({ data }) => data),

  getTransactions: ({ id, perPage, currentPage }) =>
    client
      .get(`/api/v1/getTransactions/${id}?limit=${perPage}&page=${currentPage}`)
      .then(({ data }) => data),

  getTripHistory: ({ id, perPage, currentPage }) =>
    client
      .get(`/api/v1/getTripHistory/${id}?limit=${perPage}&page=${currentPage}`)
      .then(({ data }) => data),

  getAccountSummary: ({ id, perPage, currentPage }) =>
    client
      .get(
        `/api/v1/getAccountSummary/${id}?limit=${perPage}&page=${currentPage}`
      )
      .then(({ data }) => data),
};
