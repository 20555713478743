import { useEffect, useState, useContext } from "react";

// components
import AdminHeader from "../components/AdminHeader/AdminHeader";
import AdminSidebar from "../components/AdminSidebar/AdminSidebar";

//pages
import Complaints from "../screens/Complaints/Complaints";
import AssignedTransactions from "../screens/AssignedTransactions/AssignedTransactions";
import ManageStaff from "../screens/ManageStaff/ManageStaff";
import VendorSearch from "../screens/VendorSearch/VendorRoutes";
import VendorApproval from "../screens/VendorApproval/VendorApproval";
import CustomerSearch from "../screens/CustomerSearch/CustomerRoutes";
import LeasingRoutes from "../screens/LongTermLeasing/Routes";
import CarOptions from "../screens/CarOptions/CarOptions";
import Viewinfo from "../screens/Complaints/Viewinfo";

// hooks
import useWindowDimensions from "../hooks/useWindowSize";
import { ROUTES } from "../Routes";
import { UserContext } from "../context/UserContext";
import {
  Routes as AdminRoutes,
  Route,
  useNavigate,
  Navigate,
} from "react-router-dom";
import ProtectedRoute from "../components/ProtectedRoute/ProtectedRoute";
import { JointPermissionGuard } from "../components/PermissionGuard/PermissionGuard";

const AdminDashboard = () => {
  const { width } = useWindowDimensions();
  const [isOpen, setIsOpen] = useState(true);
  const [lastFetchDate, setLastFetchDate] = useState("");
  const { user, setUser } = useContext(UserContext);
  const navigation = useNavigate();

  useEffect(() => {
    if (width < 768) {
      return setIsOpen(false);
    }
    setIsOpen(true);
  }, [width]);

  // console.log(value);

  const setDataFetchTime = () => {
    // set time that data was fetched
    const currentTime = new Date().toLocaleString("en-GB", {
      hourCycle: "h12",
      month: "long",
      day: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "numeric",
    });
    setLastFetchDate(currentTime);
  };

  const toggleSidebar = () => {
    setIsOpen((isOpen) => !isOpen);
  };

  return (
    <div>
      <AdminHeader siebarToggle={toggleSidebar} windowWidth={width} />
      <div className="flex">
        <>
          <AdminSidebar
            pages={ROUTES["admin"]}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            windowWidth={width}
            refreshTime={lastFetchDate}
          />
          <div
            style={{ flex: "4", overflowY: "hidden" }}
            className={`overflow-x-hidden mx-2 my-4 ${
              width < 768 ? "w-full" : ""
            }`}
          >
            <AdminRoutes>
              <Route
                path="/"
                element={<Navigate to={`/${user?._id}/complaints`} />}
              />
              <Route
                path="/assigned-transactions"
                element={<AssignedTransactions />}
              />
              <Route path="/complaints" element={<Complaints />} />
              <Route path="/complaints/:complaintId" element={<Viewinfo />} />
              <Route path="/manage-staff/*" element={<ManageStaff />} />
              <Route path="/vendor-search/*" element={<VendorSearch />} />
              <Route path="/customer-search/*" element={<CustomerSearch />} />
              <Route path="/vendor-approval" element={<VendorApproval />} />
              <Route
                path="/car-options"
                element={
                  <ProtectedRoute requiredPermissions={["admin"]}>
                    <CarOptions />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/longterm-leasing/*"
                element={
                  <ProtectedRoute
                    requiredPermissions={["admin", "longTermLeasing"]}
                  >
                    <LeasingRoutes />
                  </ProtectedRoute>
                }
              />
              {/* 404 page */}
              {/* <Route
                path="*"
                element={
                  <div className="flex h-full w-full justify-center items-center">
                    <h5 className="text-xl"> 404 page not found. </h5>
                  </div>
                }
              /> */}
            </AdminRoutes>
          </div>
        </>
      </div>
    </div>
  );
};

export default AdminDashboard;
