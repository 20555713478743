import React, { useState, useMemo } from "react";
import {
  CheckOutlined,
  ClearOutlined,
  CloseOutlined,
  Close,
  ArrowLeft,
  ArrowRight,
} from "@material-ui/icons";
import Approve from "./Approve";
import CenteredModal from "../../../components/Modal/CenteredModal";
import toast from "react-hot-toast";
import Empty from "../../../components/Empty";

const Car = ({ data }) => {
  const [modal, setModal] = useState(false);
  const [activeImgIndex, setActiveImgIndex] = useState(0);

  // console.log(data);

  // console.log(activeImgIndex);

  const [status, setStatus] = useState("");

  let imgViews = useMemo(
    () => [
      { id: 1, slug: "driverSide", title: "Driver Side" },
      { id: 2, slug: "frontSeat", title: "Front Seat" },
      { id: 3, slug: "frontView", title: "Front View" },
      { id: 4, slug: "passengerSide", title: "Passenger Side" },
      { id: 5, slug: "rearSeat", title: "Rear Seat" },
      { id: 6, slug: "rearView", title: "Rear View" },
    ],
    [data]
  );

  // console.log(imgViews.length);

  const handleForward = () => {
    if (activeImgIndex !== 5) {
      setActiveImgIndex(activeImgIndex + 1);
    }
  };

  const handleBackward = () => {
    if (activeImgIndex !== 0) {
      setActiveImgIndex(activeImgIndex - 1);
    }
  };

  const handleModal = () => {
    setModal(!modal);
  };

  const handleAction = (status) => {
    return () => {
      if (
        confirm(
          `Are you sure you want to ${
            status === "approved" ? "approve" : "decline"
          } this car?`
        )
      ) {
        toast.loading("Processing...");
        setStatus(status);
        setTimeout(() => {
          toast.remove();
          handleModal();
        }, 1000);
      }
    };
  };

  if (!data) {
    return <Empty />;
  }

  return (
    <>
      <CenteredModal
        title={null}
        open={modal}
        setOpen={handleModal}
        closeButtonLabel={<Close />}
      >
        <Approve
          type={1}
          handleModal={handleModal}
          id={data?._id}
          status={status}
        />
      </CenteredModal>
      <h1 className="w-full bg-darkBlue text-white mb-2 text-xl px-2 py-2">
        CAR PAPERS VERIFICATION
      </h1>
      <div
        className="flex items-center gap-3 p-4 bg-slate-50 rounded-md"
        style={{ border: "1px dotted #ccc" }}
      >
        <div
          className="flex-1 border border-rounded rounded-lg bg-white flex-col gap-2 flex items-center justify-center p-2"
          style={{ boxShadow: "0 0 2px 0 #ccc" }}
        >
          <h1 className="text-xl text-orangeBg bg-slate-100 w-full text-center rounded-lg py-1">
            VEHICLE LICENSE
          </h1>
          {/* <h1>Boye Esan</h1> */}
          <img
            src={data?.carPapers?.vehicleLicence?.url}
            loading="eager"
            alt="driver"
            className="h-60 w-full object-contain"
          />
        </div>
        <span className="font-bold text-2xl text-slate-400">=</span>
        <div
          className="flex-1 border bg-white border-rounded rounded-lg h-24 flex flex-col gap-2 items-center justify-center px-2"
          style={{ boxShadow: "0 0 2px 0 #ccc" }}
        >
          <h1 className="text-xl text-orangeBg bg-slate-100 w-full text-center rounded-lg py-1">
            NAME ON ID CARD/CAC
          </h1>
          <h1>
            {data?.carPapers?.idCard?.fNameOwner}{" "}
            {data?.carPapers?.idCard?.lNameOwner}
          </h1>
        </div>
      </div>
      {/* <div className="w-full flex items-center justify-end gap-2 p-2 mt-4">
        <div
          className="text-darkBlue font-extrabold text-2xl border border-slate-300 rounded-lg w-12 flex items-center justify-center h-12"
          style={{ boxShadow: "0 0 3px 0 #ccc" }}
        >
          <CheckOutlined />
        </div>
        <div
          className="text-orangeBg font-extrabold text-2xl border border-slate-300 rounded-lg w-12 flex items-center justify-center h-12"
          style={{ boxShadow: "0 0 3px 0 #ccc" }}
        >
          <CloseOutlined />
        </div>
      </div> */}
      <h1 className="w-full bg-darkBlue text-white my-2 text-xl px-2 py-2">
        CAR DETAILS VERIFICATION
      </h1>
      <div
        className="flex items-center gap-3 p-4 bg-slate-50 rounded-md"
        style={{ border: "1px dotted #ccc" }}
      >
        <div
          className="flex-1 border border-rounded rounded-lg bg-white flex-col gap-2 flex items-center justify-center p-2"
          style={{ boxShadow: "0 0 2px 0 #ccc" }}
        >
          <h1 className="text-xl text-orangeBg bg-slate-100 w-full text-center rounded-lg py-1">
            CAR INFORMATION
          </h1>
          <div className="w-full flex flex-col">
            <p>
              1. Plate Number ={">"} {data?.plateNumber}
            </p>
            <p>
              2. Car Make ={">"} {data?.carDetails?.carMake}
            </p>
            <p>
              3. Car Model ={">"} {data?.carDetails?.carModel}
            </p>
            <p>
              3. Capacity ={">"} {data?.carDetails?.capacity}
            </p>
            <p>
              4. Address ={">"} {data?.carDetails?.address}
            </p>
            <p>
              5. Transmission ={">"} {data?.carDetails?.transmission}
            </p>
            <p>
              6. Air Conditioner ={">"} {data?.carDetails?.airConditioner}
            </p>
            <p className="capitalize">
              7. Tinted ={">"} {data?.carDetails?.carTint}
            </p>
          </div>
        </div>
        <span className="font-bold text-2xl text-slate-400">=</span>
        <div
          className="flex-1 border border-rounded rounded-lg bg-white flex-col gap-2 flex items-center justify-center p-2"
          style={{ boxShadow: "0 0 2px 0 #ccc" }}
        >
          <h1 className="text-xl text-orangeBg bg-slate-100 w-full text-center rounded-lg py-1 uppercase">
            CAR VIEW ({imgViews[activeImgIndex]?.title})
          </h1>
          <img
            src={data?.carImages?.[imgViews[activeImgIndex].slug]?.url}
            loading="eager"
            alt="car"
            className="h-60 w-full object-contain"
          />
          <div className="w-full flex items-center justify-between p-2 my-2">
            <button
              onClick={handleBackward}
              className="text-orangeBg font-extrabold text-2xl border border-slate-300 rounded-lg w-12 flex items-center justify-center h-12"
              style={{ boxShadow: "0 0 3px 0 #ccc" }}
            >
              <ArrowLeft />
            </button>
            <button
              onClick={handleForward}
              className="text-orangeBg font-extrabold text-2xl border border-slate-300 rounded-lg w-12 flex items-center justify-center h-12"
              style={{ boxShadow: "0 0 3px 0 #ccc" }}
            >
              <ArrowRight />
            </button>
          </div>
        </div>
      </div>
      <div className="w-full flex items-center justify-end gap-2 p-2 mt-4">
        <button
          onClick={handleAction("approved")}
          className="text-darkBlue font-extrabold text-2xl border border-slate-300 rounded-lg w-12 flex items-center justify-center h-12"
          style={{ boxShadow: "0 0 3px 0 #ccc" }}
        >
          <CheckOutlined />
        </button>
        <button
          onClick={handleAction("declined")}
          className="text-orangeBg font-extrabold text-2xl border border-slate-300 rounded-lg w-12 flex items-center justify-center h-12"
          style={{ boxShadow: "0 0 3px 0 #ccc" }}
        >
          <CloseOutlined />
        </button>
      </div>
    </>
  );
};

export default Car;
