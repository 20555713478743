import React from "react";
import DisputeCall from "./DisputeCall";

const viewinfo = () => {
  return (
    <div className={`border border-slate-300 w-full p-2`}>
      <DisputeCall />
    </div>
  );
};

export default viewinfo;
