import React from "react";
import { useParams } from "react-router-dom";
import { dispute } from "../../../api/dispute";
import { useQuery } from "react-query";
import useWindowDimensions from "../../../hooks/useWindowSize";
import { Box, Flex, Text } from "@theme-ui/components";
import Loader from "../../../components/Loader/Loader";

const CustomerInfo = () => {
  const { complaintId } = useParams();
  const { width } = useWindowDimensions();

  const { data: customerInformation, isLoading } = useQuery(
    ["Customer Info", complaintId],
    async () => {
      const data = await dispute.getTripInfo(complaintId);
      return data;
    },
    {
      cacheTime: 30000,
      staleTime: 30000,
      refetchOnWindowFocus: false,
      select: (data) => data?.data,
    }
  );

  const SubInfo = ({ title, content }) => {
    return (
      <Box mb={3}>
        <Text mr={1} className="font-medium uppercase">
          {title}
        </Text>
        <Text className="capitalize">{content || "-"}</Text>
      </Box>
    );
  };

  console.log(customerInformation);

  if (isLoading) return <Loader />;

  return (
    <div className="w-full mt-2 p-5 h-[75vh] overflow-y-scroll">
      <div
        className="w-full border border-slate-300 p-4"
        style={{ boxShadow: "3px 3px 1px 0 #023047" }}
      >
        <Flex
          sx={{
            flexDirection: ["column", "row"],
          }}
          className="w-full"
        >
          <Box sx={{ flex: 1 }}>
            <SubInfo
              title={"Name:"}
              content={`${customerInformation?.customerId?.fName} ${customerInformation?.customerId?.lName}`}
            />
            <SubInfo
              title={"account type:"}
              content={customerInformation?.customerId?.accountType}
            />
            <SubInfo
              title={"E-mail:"}
              content={customerInformation?.customerId?.email}
            />
            <SubInfo
              title={"Phone Number:"}
              content={customerInformation?.customerId?.phoneNumber}
            />
          </Box>
          <Box sx={{ flex: 1 }}>
            <SubInfo
              title={"Number of bookings:"}
              content={customerInformation?.customerId?.numberOfBookings}
            />
            <SubInfo
              title={"Number of cancellations:"}
              content={customerInformation?.customerId?.numberOfCancellations}
            />
            <SubInfo
              title={"Number of Strikes:"}
              content={customerInformation?.customerId?.numberOfStrikes}
            />
            <SubInfo
              title={"Amount in holding:"}
              content={`NGN ${customerInformation?.customerId?.amountInHolding?.toLocaleString()}`}
            />
          </Box>
        </Flex>
      </div>
    </div>
  );
};

export default CustomerInfo;
