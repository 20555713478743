import React from "react";
import { SearchOutlined } from "@material-ui/icons";
import styles from "./Complaints.module.css";
import { useState } from "react";
import { activeGridFilterItemsSelector } from "@material-ui/data-grid";
import DisputeCall from "./DisputeCall";
import DisputeTable from "./DisputeTable";
import useWindowDimensions from "../../hooks/useWindowSize";
import { dispute } from "../../api/dispute";
import { useQuery, useMutation } from "react-query";
import toast from "react-hot-toast";
import { LoadScript } from "@react-google-maps/api";
import Loader from "../../components/Loader/Loader";

const Complaints = () => {
  const [activeTab, setActiveTab] = useState(0);
  const { width, height } = useWindowDimensions();

  const { mutateAsync: AcceptDispute } = useMutation(dispute.acceptDispute);

  const { data: openDispute, isLoading } = useQuery(
    ["Open Dispute"],
    async () => {
      const { data } = await dispute.getOpenDispute();
      return data;
    },
    { cacheTime: 30000, staleTime: 30000, refetchOnWindowFocus: true }
  );

  console.log(openDispute);

  const handleAccept = (id) => {
    return async () => {
      if (confirm("Kindly confirm you wish to accept this dispute.")) {
        try {
          toast.loading("Processing...");
          const data = await AcceptDispute(id);
          console.log(data);
          toast.remove();
          toast.success(data?.message);
          setTimeout(() => {
            location.reload();
          }, 1000);
        } catch ({ response }) {
          console.log(response);
          toast.remove();
          toast.error(response?.data?.message);
        }
      }
    };
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      {/* <h1 className="text-orangeBg font-bold text-2xl mb-2">Complaints</h1> */}
      <div
        className={`p-2 flex items-start justify-center gap-2 ${
          width > 600 ? "flex-row" : "flex-col"
        }`}
      >
        <div
          className={`border-x-1 border-slate-300 ${
            width > 600 ? "w-1/4" : "w-full"
          }`}
        >
          {/* <div className="flex items-center justify-center border border-slate-300 rounded">
            <SearchOutlined className="text-slate-300 text-2xl ml-2 w-1/4" />
            <input
              className={`${styles.Search} flex-1 block px-4 py-2 rounded-md outline-none`}
              text="text"
              placeholder="Search..."
            />
          </div> */}
          <div className={`${styles.DisputeList}`}>
            <ul>
              {openDispute?.map((x, index) => (
                <li
                  className={
                    activeTab === index
                      ? "flex p-3 bg-darkBlue cursor-pointer text-white"
                      : "flex p-3 bg-white cursor-pointer border border-slate-300 text-darkBlue"
                  }
                  key={index}
                  onClick={() => {
                    setActiveTab(index);
                  }}
                >
                  <div className="flex gap-3 flex-1 flex-col">
                    <h1 className="font-bold">{`${x?.hostId?.fName} ${x?.hostId?.lName}`}</h1>
                    <span>{x?.vehicleId?.carDetails?.hostAlias}</span>
                    <h3>{x?.tripDate}</h3>
                  </div>
                  <div className="px-3 flex items-end justify-end">
                    <button
                      onClick={handleAccept(x._id)}
                      className={
                        activeTab === index
                          ? "border border-siderbarBg bg-siderbarBg rounded px-4 py-2 text-orangeBg"
                          : "border border-slate-300 bg-slate-300 rounded px-4 py-2 text-darkBlue"
                      }
                    >
                      Accept
                    </button>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className={` ${width > 600 ? "w-3/4" : "w-full"}`}>
          <DisputeTable />
          {/* <DisputeCall /> */}
        </div>
      </div>
    </>
  );
};

export default Complaints;
