import React, { useState } from "react";
import { vendors } from "../../api/vendors";
import { useQuery } from "react-query";
import Loader from "../../components/Loader/Loader";
import Search from "../../components/Search/Search";
import useWindowDimensions from "../../hooks/useWindowSize";
import Pagination from "../../components/Pagination/Pagination";
import { useNavigate } from "react-router-dom";

const VendorSearch = () => {
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(5);

  const { width } = useWindowDimensions();
  const navigate = useNavigate();

  const { data: vendorList, isLoading } = useQuery(
    ["Vendors", { search, currentPage, perPage }],
    async ({ queryKey }) => {
      const data = await vendors.getVendors(queryKey[1]);
      return data;
    },
    {
      cacheTime: 30000,
      staleTime: 30000,
      refetchOnWindowFocus: true,
      select: (data) => data,
    }
  );

  // console.log(vendorList);

  const handleViewVendor = (vendor) => {
    navigate(vendor?._id);
  };

  return (
    <React.Fragment>
      <div className="p-2 gap-2 flex mt-2 mb-1">
        <Search setCurrentPage={setCurrentPage} setSearch={setSearch} />
      </div>
      <div className={`p-2 ${width < 601 ? "overflow-x-scroll" : ""}`}>
        <table
          className="w-full border border-slate-300"
          style={{ minWidth: "600px" }}
        >
          <thead className="text-white">
            <tr className="bg-darkBlue rounded-md">
              <th className="border border-darkBlue font-semibold py-3 px-2">
                FIRST NAME
              </th>
              <th className="border border-darkBlue font-semibold py-3 px-2">
                LAST NAME
              </th>
              <th className="border border-darkBlue font-semibold py-3 px-2">
                E-MAIL
              </th>
              <th className="border border-darkBlue font-semibold py-3 px-2">
                PHONE NUMBER
              </th>
            </tr>
          </thead>

          <tbody className="text-center">
            {vendorList?.data?.map((vendor, index) => (
              <tr
                className="border border-slate-300 flex-1 bg-slate-50 w-full duration-200 cursor-pointer hover:bg-slate-200"
                key={index}
                onClick={() => handleViewVendor(vendor)}
              >
                <td className="py-3 px-2">{vendor?.fName}</td>
                <td className="py-3 px-2">{vendor?.lName}</td>
                <td className="py-3 px-2">{vendor?.email}</td>
                <td className="py-3 px-2">{vendor?.phoneNumber}</td>
              </tr>
            ))}
          </tbody>
        </table>
        {isLoading && <Loader height={"100px"} />}
        <Pagination
          data={vendorList?.data_count}
          pageNo={currentPage}
          setPageNo={setCurrentPage}
          perPage={perPage}
          setPerPage={setPerPage}
        />
      </div>
    </React.Fragment>
  );
};

export default VendorSearch;
