import React from "react";
import { Routes, Route } from "react-router-dom";
import VendorSearch from "./VendorSearch";
import VendorDetails from "./VendorDetails";

function VendorRoutes() {
  return (
    <Routes>
      <Route path="/" element={<VendorSearch />} />
      <Route path="/:vendor_id" element={<VendorDetails />} />
    </Routes>
  );
}

export default VendorRoutes;
