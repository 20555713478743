import React from "react";
import Select from "react-select";

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    padding: 10,
    backgroundColor: state.isFocused ? "#023047" : "white",
    color: state.isFocused ? "white" : "black",
  }),
  control: (provided, state) => ({
    ...provided,
    border: state.isFocused && "2px solid #fb8500",
    outline: "none", // Apply custom border style
    boxShadow: state.isFocused && "0 0 0 transparent",
  }),
};

const CustomDropdown = ({
  width,
  value,
  onChange,
  options,
  isMulti,
  defaultValue,
  placeholder = null,
}) => {
  return (
    <div className={width ? width : "w-[200px]"}>
      <Select
        options={options}
        className="custom-dropdown focus:ring-0 focus:outline-none" // Apply custom CSS class
        classNamePrefix="dropdown"
        styles={customStyles}
        defaultValue={defaultValue}
        placeholder={placeholder ? placeholder : "Add Permissions"}
        value={value}
        onChange={onChange}
        isMulti={isMulti}
        isClearable={false}
        closeMenuOnSelect={false}
      />
    </div>
  );
};

export default CustomDropdown;
