import axios from "axios";
import baseUrl from "../url";
import toast from "react-hot-toast";

const client = axios.create({
  baseURL: baseUrl,
  headers: {
    "Content-Type": "application/json",
    accept: "*/*",
  },
});

client.interceptors.request.use(
  async (config) => {
    const userToken = JSON.parse(sessionStorage.getItem("user"))?.token;
    if (userToken) {
      config.headers.Authorization = `Bearer ${userToken}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

client.interceptors.response.use(
  (response) => response,
  (error) => {
    // if (error?.response?.status === 401 && error?.config?.url !== "/login") {

    // }
    return Promise.reject(error);
  }
);

export const api = {
  //LOGIN-AUTH
  login: (payload) =>
    client.post(`/api/v1/login`, payload).then(({ data }) => data),

  getOtp: (payload) =>
    client.post(`/admin/send-otp`, payload).then(({ data }) => data),

  resetPassword: (payload) =>
    client.post(`/admin/reset-password`, payload).then(({ data }) => data),

  //STAFF
  getStaff: () => client.get(`admin/staff`).then(({ data }) => data),

  updateStaff: (payload) =>
    client.put(`admin/update-staff`, payload).then(({ data }) => data),

  removeStaff: (staffID) =>
    client.put(`admin/remove/${staffID}`).then(({ data }) => data),

  deactivateStaff: (staffID) =>
    client.put(`admin/deactivate/${staffID}`).then(({ data }) => data),

  getTeams: () => client.get(`admin/teams`).then(({ data }) => data),

  inviteUser: (payload) =>
    client.post(`admin/invite-user`, payload).then(({ data }) => data),

  createTeam: (payload) =>
    client.post(`admin/team-lead`, payload).then(({ data }) => data),

  updateTeam: ({ teamID, payload }) =>
    client.put(`admin/team-lead/${teamID}`, payload).then(({ data }) => data),

  getPermissions: () => client.get(`misc/permissions`).then(({ data }) => data),

  getUserPermissions: (userID) =>
    client.get(`/admin/staff/${userID}`).then(({ data }) => data),

  updatePermissions: ({ payload, staffID }) =>
    client
      .put(`admin/permissions/${staffID}`, payload)
      .then(({ data }) => data),

  //VENDOR APPROVAL
  approveVendor: (payload) =>
    client.post(`/admin-approval`, { ...payload }).then((data) => data),

  //VENDOR SEARCH
  getVendors: (searchTerm, batch, limit, order, sortBy) =>
    client
      .get(
        `/vendor-search?searchTerm=${searchTerm}&batch=${batch}&limit=${limit}&order=${order}&sortBy=${sortBy}`
      )
      .then(({ data }) => data.data),

  getVendorData: (companyID) =>
    client.get(`/vendor-data/${companyID}`).then(({ data }) => data.data),
};
