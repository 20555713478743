import React from "react";
import styles from "../../Complaints/Complaints.module.css";
import { longtermleasing } from "../../../api/longtermleasing";
import { useQuery, useMutation } from "react-query";
import Loader from "../../../components/Loader/Loader";
import toast from "react-hot-toast";

const ViewOpenLeasing = ({ width }) => {
  const { mutateAsync: acceptLeasing } = useMutation(
    longtermleasing.acceptOpenListing
  );

  const { data, isLoading } = useQuery(
    ["Open Leasing Items"],
    async () => {
      const data = await longtermleasing.getOpenLeasing();
      return data;
    },
    {
      cacheTime: 30000,
      staleTime: 30000,
      refetchOnWindowFocus: true,
      select: (data) => data,
    }
  );

  const handleAcceptLeasing = async (id) => {
    if (confirm("Are you sure you want to accept this item?")) {
      try {
        toast.loading("Processing...");
        const data = await acceptLeasing(id);
        // console.log(data);
        toast.remove();
        toast.success(data?.message);
        setTimeout(() => {
          location.reload();
        }, 1000);
      } catch ({ response }) {
        toast.remove();
        toast.error(response?.data?.message);
      }
    }
  };

  if (isLoading) {
    return (
      <div
        className={`border-x-1 border-slate-300 ${
          width > 600 ? "w-1/4" : "w-full"
        }`}
      >
        <div className={`${styles.LeasingList}`}>
          <Loader />
        </div>
      </div>
    );
  }

  //   console.log(data);

  return (
    <div
      className={`border-x-1 border-slate-300 ${
        width > 600 ? "w-1/4" : "w-full"
      }`}
    >
      <div className={`${styles.LeasingList}`}>
        <ul>
          {data?.data?.map((item, index) => (
            <li
              className="flex p-3 bg-white cursor-pointer border-b border-r border-slate-300 text-darkBlue"
              key={index}
            >
              <div className="flex gap-3 flex-1 flex-col">
                <h1 className="font-medium uppercase text-sm">
                  {item?.fName} {item?.lName}
                </h1>
                <span>{item?.state}</span>
                <h3 className="text-sm">{item?.startDate}</h3>
              </div>
              <div className="flex items-end justify-end">
                <button
                  onClick={() => handleAcceptLeasing(item?._id)}
                  className="border border-darkBlue bg-darkBlue text-sm uppercase px-4 py-2 text-white"
                >
                  Accept
                </button>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default ViewOpenLeasing;
