import React, { useState } from "react";
import { Box, Text } from "@theme-ui/components";
import { ErrorOutlined, Close } from "@material-ui/icons";
import { useParams } from "react-router-dom";
import { vendors } from "../../../api/vendors";
import { useQuery } from "react-query";
import VerticalMenu from "../../../components/VerticalMenu/VerticalMenu";
import { vertButtonClass } from "../../ManageStaff/Style";
import Loader from "../../../components/Loader/Loader";
import Pagination from "../../../components/Pagination/Pagination";
import CenteredModal from "../../../components/Modal/CenteredModal";
import ReassignModal from "./ReassignModal";
import toast from "react-hot-toast";
import CarImage from "./CarImage";

const Cars = () => {
  const { vendor_id: id } = useParams();

  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(5);

  const [currentFleetId, setCurrentFleetId] = useState("");
  const [modal, setModal] = useState(false);

  const { data: fleets, isLoading } = useQuery(
    ["selected Fleets", { id, currentPage, perPage }],
    ({ queryKey }) => vendors.getFleets(queryKey[1]),
    {
      cacheTime: 30000,
      staleTime: 30000,
      refetchOnWindowFocus: true,
      select: (data) => data,
    }
  );

  // console.log(fleets);

  // console.log(vendor_id);

  // console.log({ data });

  if (fleets?.data?.length < 1) {
    return (
      <Box sx={{ width: "100%", textAlign: "center" }} mt={3}>
        <ErrorOutlined className="mr-1 text-orangeBg" />
        <Text>No data</Text>
      </Box>
    );
  }

  const handleReassignModal = (id) => {
    return () => {
      if (confirm("Kindly confirm you wish to re-assign this car?")) {
        toast.loading("Processing...");
        setCurrentFleetId(id);
        setTimeout(() => {
          toast.remove();
          setModal(!modal);
        }, 1000);
      }
    };
  };

  return (
    <React.Fragment>
      <CenteredModal
        title={"Re-assign Fleet"}
        open={modal}
        setOpen={() => setModal(!modal)}
        closeButtonLabel={<Close />}
      >
        <ReassignModal
          handleModal={() => setModal(!modal)}
          fleetId={currentFleetId}
        />
      </CenteredModal>
      {isLoading ? (
        <Loader />
      ) : (
        <Box
          sx={{
            marginTop: "10px",
          }}
          className="bg-slate-200 p-10 overflow-y-scroll border border-gray-400 gap-[20px] w-full max-h-[500px] grid grid-cols-1 md:grid-cols-3"
        >
          {fleets?.data?.map((car) => (
            <Box
              key={car._id}
              className=" flex flex-col p-3 gap-[10px] rounded-md border border-slate-100 shadow bg-white"
            >
              <CarImage car={car} />
              <Text>
                {car?.carDetails?.carMake}
                {" - "}
                {car?.carDetails?.carModel}
              </Text>
              <div className="w-full flex items-center justify-between">
                <Text>{car?.plateNumber}</Text>
                <VerticalMenu isBackground>
                  <div className="">
                    <button
                      onClick={handleReassignModal(car?._id)}
                      className={vertButtonClass}
                    >
                      Re-assign
                    </button>
                  </div>
                </VerticalMenu>
              </div>
            </Box>
          ))}
        </Box>
      )}
      <Pagination
        data={fleets?.data_count}
        pageNo={currentPage}
        setPageNo={setCurrentPage}
        perPage={perPage}
        setPerPage={setPerPage}
      />
    </React.Fragment>
  );
};

export default Cars;
