import React from "react";
import useWindowDimensions from "../../hooks/useWindowSize";
import { dispute } from "../../api/dispute";
import { useQuery } from "react-query";
import Loader from "../../components/Loader/Loader";
import { useNavigate } from "react-router-dom";

const DisputeTable = () => {
  //   const data = [];

  const { width } = useWindowDimensions();

  const navigate = useNavigate();

  const { data: acceptedDispute, isLoading } = useQuery(
    ["Accepted Dispute"],
    async () => {
      const { data } = await dispute.getAcceptedDispute();
      return data;
    },
    { cacheTime: 30000, staleTime: 30000, refetchOnWindowFocus: true }
  );

  // console.log(acceptedDispute);

  const handleViewDispute = (id) => {
    return () => {
      navigate(`${id}`);
    };
  };

  return (
    <div className={`w-full ${width < 601 ? "overflow-x-scroll" : ""}`}>
      <table
        className="w-full border border-slate-300"
        style={{ minWidth: "600px" }}
      >
        <thead className="text-white">
          <tr className="bg-darkBlue rounded-md">
            <th className="border border-darkBlue font-semibold py-3 px-2">
              CUSTOMER NAME
            </th>
            <th className="border border-darkBlue font-semibold py-3 px-2">
              HOST NAME
            </th>
            <th className="border border-darkBlue font-semibold py-3 px-2">
              DATE
            </th>
            <th className="border border-darkBlue font-semibold py-3 px-2">
              TRIP ID
            </th>
          </tr>
        </thead>

        <tbody className="text-center">
          {acceptedDispute?.map((item, index) => (
            <tr
              className="border border-slate-300 flex-1 bg-slate-50 w-full duration-200 cursor-pointer hover:bg-slate-200"
              key={index}
              onClick={handleViewDispute(item?._id)}
            >
              <td className="py-3 px-2">{`${item?.customerId?.fName} ${item?.customerId?.lName}`}</td>
              <td className="py-3 px-2">{`${item?.hostId?.fName} ${item?.hostId?.lName}`}</td>
              <td className="py-3 px-2">{item?.tripDate}</td>
              <td className="py-3 px-2">{item?.tripId}</td>
            </tr>
          ))}
        </tbody>
      </table>
      {isLoading && <Loader />}
      {/* <Pagination
    data={data?.metaData}
    pageNo={currentPage}
    setPageNo={setCurrentPage}
    setPerPage={setPerPage}
    perPage={perPage}
  /> */}
    </div>
  );
};

export default DisputeTable;
