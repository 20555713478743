import apiClient from "./api";

const client = apiClient();

export const staff = {
  getStaff: ({ search, status, currentPage, perPage }) =>
    client
      .get(
        `api/v1/getStaff?limit=${perPage}&page=${currentPage}&search=${search}&status=${status}`
      )
      .then(({ data }) => data),

  getCurrentStaff: (id) =>
    client.get(`/api/v1/getStaff/${id}`).then(({ data }) => data),

  updateStaff: ({ id, payload }) =>
    client.patch(`api/v1/editStaff/${id}`, payload).then(({ data }) => data),

  updateStatus: ({ id, status }) =>
    client
      .delete(`/api/v1/deactivateStaff/${id}?active=${status}`)
      .then(({ data }) => data),

  addStaff: (payload) =>
    client.post(`/api/v1/signUp`, payload).then(({ data }) => data),

  getPermissions: () =>
    client.get("/api/v1/getPermission").then(({ data }) => data),

  createPermissions: (payload) =>
    client.post("/api/v1/createPermission", payload).then(({ data }) => data),

  getAccountSummary: (id) =>
    client
      .get(`/api/v1/getAdminUserAccountSummary/${id}`)
      .then(({ data }) => data),
};
