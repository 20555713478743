import React, { useState } from "react";
import {
  CheckOutlined,
  ClearOutlined,
  CloseOutlined,
  Close,
} from "@material-ui/icons";
import Approve from "./Approve";
import CenteredModal from "../../../components/Modal/CenteredModal";
import toast from "react-hot-toast";
import Empty from "../../../components/Empty";

const Driver = ({ data }) => {
  // console.log(data);
  const [modal, setModal] = useState(false);

  const [status, setStatus] = useState("");

  const handleModal = () => {
    setModal(!modal);
  };

  const handleAction = (status) => {
    return () => {
      if (
        confirm(
          `Are you sure you want to ${
            status === "approved" ? "approve" : "decline"
          } ${data?.fName} ${data?.lName}?`
        )
      ) {
        toast.loading("Processing...");
        setStatus(status);
        setTimeout(() => {
          toast.remove();
          handleModal();
        }, 1000);
      }
    };
  };

  if (!data) {
    return <Empty />;
  }

  return (
    <>
      <CenteredModal
        title={null}
        open={modal}
        setOpen={handleModal}
        closeButtonLabel={<Close />}
      >
        <Approve
          type={2}
          handleModal={handleModal}
          id={data?._id}
          status={status}
        />
      </CenteredModal>
      <h1 className="w-full bg-darkBlue text-white mb-2 text-xl px-2 py-2">
        PICTURE VERIFICATION
      </h1>
      <div
        className="flex items-center gap-3 p-4 bg-slate-50 rounded-md"
        style={{ border: "1px dotted #ccc" }}
      >
        <div
          className="flex-1 border border-rounded rounded-lg bg-white flex-col gap-2 flex items-center justify-center p-2"
          style={{ boxShadow: "0 0 2px 0 #ccc" }}
        >
          <h1 className="text-xl text-orangeBg bg-slate-100 w-full text-center rounded-lg py-1">
            DRIVER'S PICTURE
          </h1>
          <span>
            <img
              src={data?.profilePicture?.url}
              loading="eager"
              alt="driver"
              className="h-60 w-full object-contain"
            />
          </span>
        </div>
        <span className="font-bold text-2xl text-slate-400">=</span>
        <div
          className="flex-1 border bg-white border-rounded rounded-lg flex flex-col gap-2 items-center justify-center p-2"
          style={{ boxShadow: "0 0 2px 0 #ccc" }}
        >
          <h1 className="text-xl text-orangeBg bg-slate-100 w-full text-center rounded-lg py-1">
            DRIVER'S LICENSE
          </h1>
          <span className="flex items-center jus">
            <img
              src={data?.driversLicenseImage?.url}
              loading="eager"
              alt="driver"
              className="h-60 w-full object-contain"
            />
          </span>
        </div>
      </div>
      {/* <div className="w-full flex items-center justify-end gap-2 p-2 mt-4">
        <div
          className="text-darkBlue font-extrabold text-2xl border border-slate-300 rounded-lg w-12 flex items-center justify-center h-12"
          style={{ boxShadow: "0 0 3px 0 #ccc" }}
        >
          <CheckOutlined />
        </div>
        <div
          className="text-orangeBg font-extrabold text-2xl border border-slate-300 rounded-lg w-12 flex items-center justify-center h-12"
          style={{ boxShadow: "0 0 3px 0 #ccc" }}
        >
          <CloseOutlined />
        </div>
      </div> */}
      <h1 className="w-full bg-darkBlue text-white my-2 text-xl p-2">
        LICENSE VERIFICATION
      </h1>
      <div
        className="flex items-center gap-3 p-4 bg-slate-50 rounded-md"
        style={{ border: "1px dotted #ccc" }}
      >
        <div
          className="flex-1 border border-rounded rounded-lg bg-white flex-col gap-2 flex items-center justify-center p-2"
          style={{ boxShadow: "0 0 2px 0 #ccc" }}
        >
          <h1 className="text-xl text-orangeBg bg-slate-100 w-full text-center rounded-lg py-1">
            LICENSE NUMBER
          </h1>
          <span className="h-60 w-full flex items-center justify-center font-bold text-2xl">
            {data?.driversLicenseNumber}
          </span>
        </div>
        <span className="font-bold text-2xl text-slate-400">=</span>
        <div
          className="flex-1 border bg-white border-rounded rounded-lg flex flex-col gap-2 items-center justify-center p-2"
          style={{ boxShadow: "0 0 2px 0 #ccc" }}
        >
          <h1 className="text-xl text-orangeBg bg-slate-100 w-full text-center rounded-lg py-1">
            DRIVER'S LICENSE
          </h1>
          <span>
            <img
              src={data?.driversLicenseImage?.url}
              loading="eager"
              alt="driver"
              className="h-60 w-full object-contain"
            />
          </span>
        </div>
      </div>
      <div className="w-full flex items-center justify-end gap-2 p-2 mt-4">
        <button
          onClick={handleAction("approved")}
          className="text-darkBlue font-extrabold text-2xl border border-slate-300 rounded-lg w-12 flex items-center justify-center h-12"
          style={{ boxShadow: "0 0 3px 0 #ccc" }}
        >
          <CheckOutlined />
        </button>
        <button
          onClick={handleAction("declined")}
          className="text-orangeBg font-extrabold text-2xl border border-slate-300 rounded-lg w-12 flex items-center justify-center h-12"
          style={{ boxShadow: "0 0 3px 0 #ccc" }}
        >
          <CloseOutlined />
        </button>
      </div>
    </>
  );
};

export default Driver;
