import React, { useMemo } from "react";
import { GavelOutlined, ErrorOutlineOutlined } from "@material-ui/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeftLong,
  faHandshakeAlt,
} from "@fortawesome/free-solid-svg-icons";
import { Close } from "@material-ui/icons";
import styles from "./Complaints.module.css";
import { useState } from "react";
import Dispute from "./components/Dispute";
import ArrivalForm from "./components/ArrivalForm";
import Chat from "./components/Chat";
import TripInfo from "./components/TripInfo";
import TripRequest from "./components/TripRequest";
import CustomerInfo from "./components/CustomerInfo";
import CustomerChat from "./components/CustomerChat";
import HostChat from "./components/HostChat";
import { useNavigate } from "react-router-dom";
import { LoadScript } from "@react-google-maps/api";
import CenteredModal from "../../components/Modal/CenteredModal";
import SettleDispute from "./components/SettleDispute";

const DisputeCall = () => {
  const sessionTabIndex = sessionStorage.getItem("session_dispute_tab_index");
  const [currentTabIndex, setCurrentTabIndex] = useState(
    parseFloat(sessionTabIndex) || 0
  );

  const navigate = useNavigate();

  const [modal, setModal] = useState(false);

  const tabs = useMemo(
    () => [
      { id: 1, title: "Trip Info", page: <TripInfo /> },
      { id: 2, title: "Trip Request", page: <TripRequest /> },
      { id: 3, title: "Customer Info", page: <CustomerInfo /> },
      { id: 4, title: "Read Chat", page: <Dispute /> },
      { id: 5, title: "Customer Chat", page: <CustomerChat /> },
      { id: 6, title: "Host Chat", page: <HostChat /> },
    ],
    [currentTabIndex]
  );

  const handleBack = () => {
    navigate(-1);
    sessionStorage.removeItem("session_dispute_tab_index");
  };

  const handleModal = () => {
    setModal(!modal);
  };

  return (
    <>
      <CenteredModal
        title={"Settle Dispute"}
        open={modal}
        setOpen={handleModal}
        closeButtonLabel={<Close />}
      >
        <SettleDispute handleModal={handleModal} />
      </CenteredModal>
      <LoadScript
        googleMapsApiKey={`AIzaSyD7BtcQzZrJqA3BW1gYOutrHoesvzakF8c`}
        libraries={["places"]}
      >
        <div className="flex p-2 items-center justify-between mb-2 gap-2">
          <button
            onClick={handleBack}
            className="px-5 py-2 rounded-md bg-slate-300"
          >
            <FontAwesomeIcon icon={faArrowLeftLong} />
          </button>
          <div className="flex items-center justify-end gap-2">
            {/* <a title="Settle Dispute">
            <div className="border border-slate-300 bg-orange-100 font-bold text-2xl p-2 w-12 flex items-center justify-center cursor-pointer text-darkBlue rounded-lg shadow hover:bg-darkBlue hover:text-orange-100 hover:shadow-none duration-200">
              <FontAwesomeIcon icon={faHandshakeAlt} />
            </div>
          </a> */}
            <a title="Pass Judgement">
              <button
                onClick={handleModal}
                className="border border-slate-300 bg-orange-100 font-bold text-4xl p-2 w-12 flex items-center justify-center cursor-pointer text-darkBlue rounded-lg shadow hover:bg-darkBlue hover:text-orange-100 hover:shadow-none duration-200 relative"
              >
                <GavelOutlined />
              </button>
            </a>
            {/* <a title="Customer Information">
            <div className="border border-slate-300 bg-orange-100 font-bold text-4xl p-2 w-12 flex items-center justify-center cursor-pointer text-darkBlue rounded-lg shadow hover:bg-darkBlue hover:text-orange-100 hover:shadow-none duration-200">
              <ErrorOutlineOutlined />
            </div>
          </a> */}
          </div>
        </div>
        <div className={`${styles.DisputeCall}`}>
          <ul className="flex-wrap flex items-center justify-start">
            {tabs?.map((tab, index) => (
              <li
                key={index}
                onClick={() => {
                  setCurrentTabIndex(index);
                  sessionStorage.setItem("session_dispute_tab_index", index);
                }}
                className={
                  currentTabIndex === index
                    ? "border border-darkBlue text-sm uppercase bg-darkBlue text-white"
                    : "uppercase text-sm"
                }
              >
                {tab.title}
              </li>
            ))}
          </ul>
          {tabs[currentTabIndex].page}
        </div>
      </LoadScript>
    </>
  );
};

export default DisputeCall;
