import React, { useState } from "react";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import toast from "react-hot-toast";
import { useForm } from "react-hook-form";
import { codes } from "../helpers/countryCode";
import { useMutation } from "react-query";
// import { api } from "../api";
import { auth } from "../api/auth";

const PasswordReset = ({ hideModal }) => {
  const otpSent = JSON.parse(sessionStorage.getItem("otpSent"));

  const [requestOTP, setRequestOTP] = useState(otpSent || false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [type, setType] = useState("email");

  const { register, handleSubmit } = useForm();
  const { mutateAsync: requestUserOtp } = useMutation(auth.getOtp);
  const { mutateAsync: resetUserPassword } = useMutation(auth.resetPassword);

  const handleType = (e) => {
    e.preventDefault();
    setType(e.target.value);
  };

  const getOtp = async (values) => {
    toast.loading("Processing...");

    try {
      const data = await requestUserOtp(values);
      // console.log({ data });
      toast.remove();
      toast.success(data?.message);
      setRequestOTP(true);
    } catch ({ response }) {
      // console.log({ response });
      toast.remove();
      toast.error(response?.data?.message);
      // setRequestOTP(false);
    }
  };

  const resetPassword = async (values) => {
    if (values?.password !== values?.confirmPassword) {
      return toast.error("Passwords do not match...");
    }

    const { oldPassword, password, confirmPassword } = values;

    // console.log(values);

    toast.loading("Processing...");
    // console.log(values);
    const payload = { oldPassword, password, confirmPassword };
    // console.log(payload);

    try {
      const data = await resetUserPassword(payload);
      // console.log(data);
      toast.remove();
      toast.success(data?.message);
      sessionStorage.removeItem("otpSent");
      hideModal();
      location.reload();
    } catch ({ response }) {
      // console.log({ response });
      toast.remove();
      toast.error(response?.data?.message);
    }
  };

  return (
    <div>
      {!requestOTP && (
        <>
          <div>
            <form onSubmit={handleSubmit(getOtp)}>
              <div className="w-full flex items-center justify-center gap-3">
                {/* <div className="flex-1 flex items-start flex-col justify-center gap-1">
                  <p>Select Type</p>
                  <select
                    className="w-full flex-1 border border-slate-300 block px-4 py-2 rounded-md outline-none bg-slate-100 duration-200 focus:ring-0 focus:px-5 focus:border-orangeBg focus:bg-white"
                    defaultValue={"Nigeria"}
                    value={type}
                    onChange={handleType}
                  >
                    <option value={"email"}>E-mail</option>
                    <option value={"phone"}>SMS</option>
                  </select>
                </div> */}
                <div className="flex-1 flex items-start flex-col justify-center gap-1">
                  <p>Email Address</p>
                  <input
                    className="w-full flex-1 border border-slate-300 block px-4 py-2 rounded-md outline-none bg-slate-100 duration-200 focus:ring-0 focus:px-5 focus:border-orangeBg focus:bg-white"
                    required
                    type={"email"}
                    {...register("email", {
                      required: true,
                    })}
                  />
                </div>
                {/* <div className="flex-1 flex items-start flex-col justify-center gap-1">
                  <p>Select Country</p>
                  <select
                    className="w-full flex-1 border border-slate-300 block px-4 py-2 rounded-md outline-none bg-slate-100 duration-200 focus:ring-0 focus:px-5 focus:border-orangeBg focus:bg-white"
                    defaultValue={"Nigeria"}
                    {...register("country", {
                      required: true,
                    })}
                  >
                    {codes?.map((country, index) => (
                      <option key={index} value={country?.name}>
                        {country?.name}
                      </option>
                    ))}
                  </select>
                </div> */}
              </div>
              <div className="w-full flex items-center justify-start">
                <input
                  type="submit"
                  value="Request OTP"
                  className="mt-5 px-5 py-2 border border-orangeBg cursor-pointer bg-orangeBg text-white rounded duration-100 hover:text-darkBlue hover:bg-orange-200 hover:border-orange-200"
                />
              </div>
            </form>
          </div>
        </>
      )}
      {requestOTP && (
        <div>
          <form onSubmit={handleSubmit(resetPassword)}>
            <div className="w-full flex items-center justify-center gap-3">
              <div className="flex-1 flex items-start flex-col justify-center gap-1">
                <p>Temporary Password</p>
                <input
                  className="w-full flex-1 border border-slate-300 block px-4 py-2 rounded-md outline-none bg-slate-100 duration-200 focus:ring-0 focus:px-5 focus:border-orangeBg focus:bg-white"
                  {...register("oldPassword", {
                    required: true,
                  })}
                  type="text"
                />
              </div>
              <div className="flex-1 flex items-start flex-col justify-center gap-1">
                <p>Reset Password</p>
                <div className="w-full relative">
                  <input
                    className="w-full flex-1 border border-slate-300 block px-4 py-2 rounded-md outline-none bg-slate-100 duration-200 focus:px-5 focus:border-orangeBg focus:bg-white focus:ring-0"
                    {...register("password", {
                      required: true,
                    })}
                    type={showPassword ? "text" : "password"}
                  />
                  {showPassword && (
                    <Visibility
                      className="text-slate-400 text-lg absolute top-2 right-3 cursor-pointer"
                      onClick={() => {
                        setShowPassword(!showPassword);
                      }}
                    />
                  )}
                  {!showPassword && (
                    <VisibilityOff
                      className="text-slate-400 text-lg absolute top-2 right-3 cursor-pointer"
                      onClick={() => {
                        setShowPassword(!showPassword);
                      }}
                    />
                  )}
                </div>
              </div>
              <div className="flex-1 flex items-start flex-col justify-center gap-1">
                <p>Confirm Password</p>
                <div className="w-full relative">
                  <input
                    className="w-full flex-1 border border-slate-300 block px-4 py-2 rounded-md outline-none bg-slate-100 duration-200 focus:px-5 focus:border-orangeBg focus:bg-white focus:ring-0"
                    {...register("confirmPassword", {
                      required: true,
                    })}
                    type={showConfirmPassword ? "text" : "password"}
                  />
                  {showConfirmPassword && (
                    <Visibility
                      className="text-slate-400 text-lg absolute top-2 right-3 cursor-pointer"
                      onClick={() => {
                        setShowConfirmPassword(!showConfirmPassword);
                      }}
                    />
                  )}
                  {!showConfirmPassword && (
                    <VisibilityOff
                      className="text-slate-400 text-lg absolute top-2 right-3 cursor-pointer"
                      onClick={() => {
                        setShowConfirmPassword(!showConfirmPassword);
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="w-full flex items-center justify-start">
              <input
                type="submit"
                value="Reset Password"
                className="mt-5 px-5 py-2 border border-orangeBg bg-orangeBg text-white rounded duration-100 hover:text-darkBlue hover:bg-orange-200 hover:border-orange-200"
              />
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default PasswordReset;
